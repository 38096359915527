import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
 
import TopBar from '../TopBar/TopBar';
import Footer from '../Footer/Footer';
import TemplateLoader from './TemplateLoader/TemplateLoader';

function MasterTemplate() {
    return (
        <Router>
            <TopBar />
            <main>
                <TemplateLoader />
            </main>
            <Footer />
        </Router>           
    );
}

export default MasterTemplate;