import React from 'react';

const Sidebar = () => {
    return (
        <div className="tw-h-full tw-w-64 tw-bg-gray-100">
            <div className="p-5">Sidebar Menu</div>
            <ul className="space-y-2 p-5">
                <li>Home</li>
                <li>About</li>
                <li>Contact</li>
            </ul>
        </div>
    );
};

export default Sidebar;