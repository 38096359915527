import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './SpecialOffer.module.css';

const SpecialOffer = ({item}) => {
  let [visability , setVisability] = useState(false);
  let active = item.IsSpecialOffer;

  const toggleOffer = (item) => {
    setVisability(!visability);
  }
  return (
    <>
      {active && 
      <>
        
          {
          (visability) ? <div className={styles.SpecialOffer + " tw-absolute tw-bottom-0 tw-top-0 tw-w-full tw-h-full "}>
            <div className="tw-bg-[#fff] tw-bg-opacity-75 tw-py-12 tw-px-3 tw-absolute tw-top-0 tw-right-0 tw-h-full tw-flex tw-flex-col tw-items-center tw-text-center tw-w-full">
              <h5>{item.SpecialOfferTitle}</h5>
              {item.SpecialOfferDescription}
            </div></div> : null
          }

        
        {active && <button type="button" className="btn btn-dark  dropdown-toggle !tw-rounded-none tw-absolute tw-right-0 tw-bottom-0 " onClick={toggleOffer} >Special Offer</button>}

      </> }
    </>
  );
};

SpecialOffer.propTypes = {};

SpecialOffer.defaultProps = {};

export default SpecialOffer;
