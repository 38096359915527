import { useEffect, useState, useContext } from 'react';
import { SiteSettingsContext, CurrencyContext } from '../../contexts/SiteSettingsProvider';
import WidgetsList from 'components/WidgetsList/WidgetsList';
import SkeletonInspiration from './SkeletonInspiration/SkeletonInspiration';

const InspirationPage = ({ pageId, keyID }) => {
    const [currency, setCurrency] = useContext(CurrencyContext);
    const [widgets, setWidgets] = useState([]);
    const [response, setResponse] = useState(null);
    const [title, setTitle] = useState(null);
    const [content, setContent] = useState(null);
    const [settings, setSettings] = useState(null);

    const getContent = (sortDirection, Sort, Show, Page, clear) => {

        if (pageId) {
            fetch(`${process.env.REACT_APP_BASE_URL}apis/inspirationpage?pageid=${pageId}&Currency=${currency}`)
                .then(response => response.json())
                .then(data => {
    
                    setResponse(true);
                    setWidgets(data.Inspiration.Widgets);
                    setTitle(data.Inspiration.SubTitle);
                    setContent(data.Inspiration.Content);
                    setSettings(data.Settings.SearchFormSettings);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }

    useEffect(() => {
        getContent();
    }, [pageId, currency])

    if (!response) {
        return <SkeletonInspiration />
    }

    return (
        <>
    
            {(title || content) &&
                <div>
                    <div className="tw-container tw-m-auto  ">
                        {title && <h1>{title}</h1>}

                        {content && <div dangerouslySetInnerHTML={{ __html: content }}></div>}
                    </div>
                </div>
            }


            <WidgetsList Widgets={widgets} pageId={pageId} keyID={keyID} settings={settings} />
        </>
    );
};



export default InspirationPage;
