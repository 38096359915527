import { useState, useEffect, useCallback } from "react";

/**
 * Hook for handling closing when clicking outside of an element
 * @param {React.node} el
 * @param {boolean} initialState
 */
export const useDetectOutsideClick = (el, initialState) => {

    const [isActive, setIsActive] = useState(initialState);
    
 
    useEffect(() => {
        const listener = e => {
            // If the active element exists and is clicked outside of
            if (el.current !== null && !el.current.contains(e.target)) {
                setIsActive(!isActive);
            }
        };
 
        // If the item is active (ie open) then listen for clicks outside
        if (isActive) {
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
        }

        return () => {
            if (!isActive){
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            }
            
        };
        
    }, [isActive, el]);

    return [isActive, setIsActive];
};


export default useDetectOutsideClick;
