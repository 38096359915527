import React from 'react';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';
import PropertiesList from 'pages/ContentPage/Properties/PropertiesList.js';

const PropertyPickerWidgetDefault = ({ data }) => {
   
    return (
        <WidgetWrapper data={data}>
         
            {data.Title && <h2 >{data.Title}</h2>}
            {data.SubTitle && <h3>{data.SubTitle}</h3>}

            <PropertiesList 
                propertyList={data.PropertiesList} 
                itemsPerRow={6}
            />
        </WidgetWrapper>
    )
};

export default PropertyPickerWidgetDefault;