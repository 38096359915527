import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import "./GuestsCounter.css";
import useGetUrlParams from 'utils/useGetUrlParams';

const GuestsCounter = ({ max, min, name, initial, upCount, settings, clearField }) => {
  const [count, setCount] = useState(initial);
  let [urlParams] = useGetUrlParams(name);

  useEffect(() => {
    if (clearField > 0) {
      
      setCount(parseInt(min));
      upCount(parseInt(min));
    }
  }, [clearField]);

  useEffect(() => {
    // console.log(urlParams, name); 
      if (urlParams && urlParams !== ''){
        changeCount(parseInt(urlParams));
      }else{
        changeCount(initial);
      }
  }, [urlParams]);

  const changeCount = (val) => {
    setCount(val);
    upCount(val);
  };

  const plus = () => {
    if (count >= max) return;
    setCount(count + 1);
    upCount(count + 1); 
  };

  const minus = () => {
    if (min){
      if (count <= min){
        return;
      }
    }
    if (count <= 0) return;
    setCount(count - 1);
    upCount(count-1);
  };

  return (
    <div className="GuestsCounter">
      <div className="tw-flex tw-min-w-[250px] tw-justify-between tw-items-center tw-my-5">
        <span className="GuestsCounterLabel">
          <b>{name === 'Adults' && (settings?.Dictionaries['SearchForm.Adults'] || name)}</b>
          {name === 'children' &&
            <>
              <b>{(settings?.Dictionaries['SearchForm.Children'] || name)}</b>
              <br />
              <>{settings?.Dictionaries['SearchForm.Children.Age'] || 'Ages 2-12'}</>
            </>
          }
          {name === 'Infants' &&
            <>
              <b>{(settings?.Dictionaries['SearchForm.Infants'] || name)}</b>
              <br />
              <>{settings?.Dictionaries['SearchForm.Infants.Age'] || 'Under 2'}</>
            </>
          }
        </span>
        <div className="tw-flex tw-justify-center tw-items-center ">
          <button onClick={minus} className="tw-flex tw-justify-center tw-items-center tw-w-[30px] tw-h-[30px] tw-rounded-full tw-border tw-border-gray-600" >
            <svg className="minusSVG" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false"  ><path d="m2 16h28"></path></svg>
          </button>  
          <span className="tw-min-w-[30px] tw-text-center">{count}</span> 
          <button onClick={plus} className="tw-flex tw-justify-center tw-items-center tw-w-[30px] tw-h-[30px] tw-rounded-full tw-border tw-border-gray-600">
            <svg className="plusSVG" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" ><path d="m2 16h28m-14-14v28"></path></svg>
          </button>
        </div>
      </div>
    </div>
  )
};

GuestsCounter.propTypes = {};

GuestsCounter.defaultProps = {};

export default GuestsCounter;
