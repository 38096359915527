import React from 'react';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';
import './LocationPickerWidget.scss';

const LocationPickerWidget = ({ data }) => {
    const { Locations } = data;
    const maxColumns = 4;  // Maximum number of columns you want to have.
    const numberOfLocations = Locations.length;
    let remainingLocations = numberOfLocations;
    let rows = [];

    // Calculate rows
    while (remainingLocations > 0) {
        const itemsInRow = remainingLocations >= maxColumns ? maxColumns : remainingLocations;
        rows.push(itemsInRow);
        remainingLocations -= itemsInRow;
    }

    return (
        <WidgetWrapper data={data}>
            <div className="tw-p-5 tw-bg-white tw-shadow-lg tw-rounded-md overflow-visible">
                <h2 className="tw-text-2xl tw-mb-3">{data.LocationsTitle}</h2>
                <div className="tw-mb-5" dangerouslySetInnerHTML={{ __html: data.LocationsDescription }} />
                <div className="tw-grid tw-gap-4">
                    {rows.map((rowSize, rowIndex) => (
                        <div key={rowIndex} className={`tw-grid tw-grid-cols-${rowSize} tw-gap-4 tw-gap-y-4`}>
                            {Locations.slice(maxColumns * rowIndex, maxColumns * rowIndex + rowSize).map((location, index) => (
                                <div key={index} className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-gray-50 tw-p-3 tw-rounded tw-shadow-md tw-text-center overflow-visible">
                                    <a href={location.LocationUrl} className="tw-no-underline">
                                        <div className="tw-overflow-hidden">
                                            <img src={location.LocationImages.Url} alt={location.LocationImages.Name} className="tw-max-h-[310px] tw-transition-all tw-duration-300 tw-transform hover:tw-scale-105" />
                                        </div>
                                        <h3 className="tw-text-lg tw-font-semibold tw-text-black tw-mt-2 tw-mb-0">{location.LocationName}</h3>
                                    </a>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>
        </WidgetWrapper>
    );
};

export default LocationPickerWidget;