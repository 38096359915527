import React from 'react';
import PropertyPickerWidgetsDefault from './PropertyPickerWidgetDefault/PropertyPickerWidgetDefault';
import PropertyPickerWidgetsCarousel from './PropertyPickerWidgetCarousel/PropertyPickerWidgetCarousel';
 

const PropertyPickerWidgets = ({ data }) => {
    const { Template } = data;
    switch (Template) {
        case 'TBS_Widget_Property_Picker':
            return <PropertyPickerWidgetsDefault data={data} />;
        case 'TBS_Widget_Property_Picker_Carousel':
            return <PropertyPickerWidgetsCarousel data={data} />;
        default:
            return null;
    }
}

export default PropertyPickerWidgets;