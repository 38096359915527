import React, { useEffect } from 'react';
import './GoogleTranslateSwitcher.css'; // Add the CSS file to your component

const GoogleTranslateSwitcher = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    document.body.appendChild(script);
  }, []);

  window.googleTranslateElementInit = function () {
    new window.google.translate.TranslateElement({ pageLanguage: 'en', includedLanguages: 'en,de,fr,pt,es,it,nl' }, 'google_translate_element');
  };

  const changeLanguage = (event) => {
    const lang = event.target.value;
    const select = document.querySelector("#google_translate_element select");
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].value.substr(0, 2) === lang) {
        select.selectedIndex = i;
        const event = new Event('change');
        select.dispatchEvent(event);
        break;
      }
    }
  };

  return (
    <div>
      <div id="google_translate_element" />
      <select id="custom_translate_select" onChange={changeLanguage} className="focus:tw-outline-none form-select">
        <option value="en">English</option>
        <option value="de">German</option>
        <option value="fr">French</option>
        <option value="pt">Portuguese</option>
        <option value="es">Spanish</option>
        <option value="it">Italian</option>
        <option value="nl">Dutch</option>
      </select>
    </div>
  );
};

export default GoogleTranslateSwitcher;