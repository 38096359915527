import React, { useEffect, useState } from 'react';
import useGetUrlParams from 'utils/useGetUrlParams';

const LocationMobile = ({
    onToggle,
    changeLocation,
    locationList,
    enableLocationFilter,
    clearField,
    onCloseSelect,
    settings,
    pageID,
    isOpen
}) => {
    const [selectedOption, setSelectedOption] = useState([]);
    const [locationPlaceholder, setLocationPlaceholder] = useState("Anywhere");
    const [Location] = useGetUrlParams('Location');

    useEffect(() => {
        if (clearField > 0) {
            setSelectedOption([]);
            changeLocation('');
            setLocationPlaceholder("Anywhere");
        }
    }, [clearField]);

    useEffect(() => {
        if (pageID && locationList) {
            const optionMatchingPageID = locationList.find(option => option.id === pageID);
            if (optionMatchingPageID) {
                setSelectedOption([{ name: optionMatchingPageID.Name, id: optionMatchingPageID.Id }]);
                setTimeout(() => changeLocation(pageID.toString()), 1);
            }
        }
    }, [pageID, locationList]);

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        let location = urlParams.get('location') || urlParams.get('Location');
        let currentLocation = Location || location;

        const options = locationList.filter(({ level }) => level === 3).map(({ Id, Name }) => ({ name: Name, id: Id }));

        if (currentLocation) {
            changeLocation(currentLocation);
            const selectedIds = currentLocation.split(',').filter(Boolean).map(Number);
            const selectedOptions = options.filter(option => selectedIds.includes(option.id));
            setSelectedOption(selectedOptions);
            setLocationPlaceholder(selectedOptions.length ? `Locations (${selectedOptions.length})` : "Anywhere");
        }

        if (currentLocation === 'All' || currentLocation === '') {
            setLocationPlaceholder("Anywhere");
            changeLocation('');
            console.log('currentLocation', currentLocation);
        }
    }, [Location, locationList]);

    const options = locationList
        ?.filter(({ level }) => level === 3)
        .map(({ Id, Name }) => ({
            name: Name,
            id: Id,
            selected: selectedOption.some(option => option.id === Id)
        })) || [];

    const changeLocaationParameter = (options) => {
        if (options.length === 0) setLocationPlaceholder("Anywhere");
        else setLocationPlaceholder(`Locations (${options.length})`);
        const ids = options.map(option => option.id).join(',');
        changeLocation(ids);
    };

    const handleCheckboxChange = (e, option) => {
        const updatedSelectedOptions = e.target.checked
            ? [...selectedOption, option]
            : selectedOption.filter(selected => selected.id !== option.id);

        setSelectedOption(updatedSelectedOptions);
        changeLocaationParameter(updatedSelectedOptions);
    };

    return (
        <div className={isOpen ? "tw-border tw-p-5" : ""}>
            <div className={!isOpen ? "tw-border tw-p-5" : ""} onClick={onToggle}>
                <h5 className='tw-m-0'>
                    {locationPlaceholder === "Anywhere" ? <span>Where to?</span> : <span>{locationPlaceholder}</span>}
                </h5>
            </div>
            {isOpen && enableLocationFilter && options.length > 0 && (
                <div className="location-buttons tw-mt-5">
                    {options.map(option => (
                        <label key={option.id} className={`location-button ${option.selected ? 'selected' : ''}`}>
                            <input
                                type="checkbox"
                                checked={option.selected}
                                onChange={e => handleCheckboxChange(e, option)}
                                className="hidden-checkbox"
                            />
                            {option.name}
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
};


export default LocationMobile;
