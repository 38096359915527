export function getcookie(c_name) {
    var c_value = document.cookie;
    var c_start = c_value.indexOf(' ' + c_name + '=');
    if (c_start === -1) {
        c_start = c_value.indexOf(c_name + '=');
    }
    if (c_start === -1) {
        return null;
    }
    c_start = c_value.indexOf('=', c_start) + 1;
    var c_end = c_value.indexOf(';', c_start);
    if (c_end === -1) {
        c_end = c_value.length;
    }
    return unescape(c_value.substring(c_start, c_end));
}

export function setCookie(name, value) {
    var expire = new Date();
    expire = new Date(expire.getTime() + 15552000000);
    document.cookie = name + '=' + value + ';path=/;expires=' + expire;
    return getcookie(name);
}

export function deletecookieBF(c_name) {
    document.cookie = c_name + '=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}