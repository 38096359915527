import React, { useEffect, useState, useRef }from 'react';
import   './SearcResult.css';
import axios from 'axios';
import { Swiper, SwiperSlide} from 'swiper/react';
 
import ReactPaginate from 'react-paginate';
import { Spinner } from "react-bootstrap";
 
import SkeletonProperty from '../SkeletonProperty/SkeletonProperty';
 
import PropertyItem from 'components/SearchResult/PropertyItem/PropertyItem';
import useHover from 'utils/useHover';
import 'react-loading-skeleton/dist/skeleton.css'

import 'swiper/css';
import 'swiper/css/lazy';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
 
 

const SearchResult = ({ transferDataToMap, enableImageScrollParam, displayMode, params, setParams, setLoadingStatus   }) => {
 
  const [results, setResults] = useState([]);
  const [linkParams, setLinkParams] = useState('');
  const [pageCount, setPageCount] = useState(0);
  const [dictionary, setDictionary] = useState(0);
  
  const [isFetching, setFetching] = useState(false);
  const [gridClasses, setGridClasses] = useState('tw-grid sm:tw-grid-cols-2  xl:tw-grid-cols-2 tw-gap-4');
  const [total, setTotal] = useState(0);
  const [messageText, setMessageText] = useState('');
  const [enableSlider, setEnableSlider] = useState();

  
   
 
  let domain = process.env.REACT_APP_BASE_URL;
  let APIUrl = "apis/searchproperties";

  useEffect(() => {
    setEnableSlider(enableImageScrollParam);
  }, [enableImageScrollParam]);
 
  useEffect(() => {
    setPageCount(Math.ceil(total / 20));
  }, [total]);

  useEffect(() => {
    if (displayMode === 'map') {
      setGridClasses("tw-grid sm:tw-grid-cols-1  md:tw-grid-cols-2 2xl:tw-grid-cols-3 tw-gap-4")
    }
    if (displayMode === 'fullWidth') {
      setGridClasses("tw-grid sm:tw-grid-cols-2  xl:tw-grid-cols-5 tw-gap-4")
    }
  }, [displayMode]);

  function handlePageClick(e){
    setParams({ ...params, Show: "21", Page: e.selected + 1, clearcache: false});
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

 
  
  useEffect(() => {

    setLoadingStatus(true);
     
    if (Object.keys(params).length === 0 && params.constructor === Object) {
      return;
    }
    params.Show = 21;
    setResults([]);
 
    if (linkParams.length === 0) {
      const currentUrl = new URL(window.location.href);
      const searchParams = new URLSearchParams(currentUrl.search);
      
      for (const [key, value] of searchParams) {
        params[key] = value;
      }
    }

 
    
    setFetching(false);
    setMessageText("");
    axios.get(domain + APIUrl, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      params: params,
    },
    ).then(function (response) {
        // handle success
        transferDataToMap(response.data.Items);
        setResults(response.data.Items);
        setTotal(response.data.Total);
        setDictionary(response.data.Dictionaties);
        if (response.data.Items.length === 0 ){  
          setMessageText("Sorry, we can't find anything");
        } 
        setTimeout(function () { setFetching(true) }, 150)
      })
      .catch(function (error) {
        // handle error
        setMessageText(error+' Error');
      })
      .finally(function () {
        setLoadingStatus(false);
      });
    
    

    const filteredObject = Object.entries(params)
      .filter(([key]) => ![
        'clearcache',
        'Location',
        'PriceFilterType',
        'Nightly',
        'NightlyPriceRange',
        'WeeklyPriceRange',
        'Currency',
        'Attributes',
        'Show'
      ].includes(key))
      .reduce((obj, [key, value]) => {
        if (key === 'CheckIn' || key === 'CheckOut') {
          if (value) {
            const date = new Date(value);
            obj[key] = date.toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            });
          }
        } else {
          obj[key] = value;
        }

        return obj;
      }, {});  


    const urlString = Object.entries(filteredObject)
      .map(pair => pair.map(encodeURIComponent).join('='))
      .join('&');

    setLinkParams('?'+ urlString);
     
  }, [params])

 
 
  const listItems = results.map((item, index) =>{
    return (
      <PropertyItem key={index} dictionary={dictionary} item={item} index={index} linkParams={linkParams} enableSlider={enableSlider} />
    );
  }
  );

  return (
    <> 
 

      {!isFetching ? <h6>  <Spinner variant="primary" animation="border" size="sm" /> We are looking the best properties for you </h6>
        : <h6>{total} Properties are available  </h6>}

      {(messageText.length > 0) ? <h4>{messageText}</h4> :null}
      <div className={gridClasses}>
        {isFetching || <SkeletonProperty count={20} />}
        {listItems}
      </div>  
  

      {(messageText.length === 0 && total>20) ? <ReactPaginate
        className='pagination tw-mt-5'
        breakLabel="..."
        previousLabel="<"
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={3}
        pageCount={pageCount}
        renderOnZeroPageCount={null}

        pageClassName='page-item'
        pageLinkClassName="page-link"
        previousClassName="page-item "
        nextClassName="page-item"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        
        activeClassName="active tw-cursor-default"
        disabledClassName='disabled'
        breakClassName="page-item"
        breakLinkClassName='page-link'
      />
     : null}

    
  
       
    </>

  );
};


export default SearchResult;