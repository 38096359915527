import React from 'react';

const VideoWidget = ({ data }) => {
    return (
        <div>
            <h3>Video Widget</h3>
            <p>Sort order: {data.SortOrder}</p>
        </div>
    )
};

export default VideoWidget;