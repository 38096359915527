import React from 'react';
import PropertiesList from 'pages/ContentPage/Properties/PropertiesList.js';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';

const RandomPropertyWidget = ({ data }) => {

    return (
        <WidgetWrapper data={data}> 
            {data.Title && <h2>{data.Title}</h2>}
            {data.Content && < div dangerouslySetInnerHTML={{ __html: data.Content }} />}    
            <PropertiesList propertyList={data.PropertiesList} />
        </WidgetWrapper>
    )
};

export default RandomPropertyWidget;