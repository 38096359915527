import React from 'react'; 
import GalleryWidgetMain from './GalleryWidget/GalleryWidget';
import GalleryWidget1 from './GalleryWidget1/GalleryWidget1';
import MasonryWidget from './MasonryWidget/MasonryWidget';
import CarouselWidget from './CarouselWidget/CarouselWidget';

const GalleryWidget = ({ data }) => {
    const { Template } = data;

    switch (Template) {
        case 'TBS_Widget_Gallery':
            return <GalleryWidgetMain data={data} />;
        case 'TBS_Widget_Gallery_1':
            return <GalleryWidget1 data={data} />;
        case 'TBS_Widget_Masonry':
            return <MasonryWidget data={data} />;
        case 'TBS_Widget_Gallery_Carousel':
            return <CarouselWidget data={data} />;
        default:
            return null;
    }
}

export default GalleryWidget;