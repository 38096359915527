import React, { useEffect, useState } from 'react';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';
import './GalleryWidget.scss';

const GalleryWidget = ({ data }) => {
    const { Title, SubTitle, Content, FeaturedItems } = data;

    const [gridClasses, setGridClasses] = useState('sm:tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4 tw-gap-y-6');

    useEffect(() => {
        if (FeaturedItems){
            const itemsCount = FeaturedItems.length;
            let itemsInRow;
            if (itemsCount > 5) {
                if (itemsCount % 2 === 0) {
                    itemsInRow = itemsCount / 2;
                } else if (itemsCount % 3 === 0) {
                    itemsInRow = itemsCount / 3;
                } else {
                    itemsInRow = Math.floor(itemsCount / 2);
                }
            } else {
                itemsInRow = itemsCount;
            }
            setGridClasses(`tw-grid sm:tw-grid-cols-1 md:tw-grid-cols-${itemsInRow} lg:tw-grid-cols-${itemsInRow} tw-gap-4 tw-gap-y-6`);
        }

    }, [FeaturedItems]);

    const renderFeature = (feature, featureIcon) => {
        if (feature && feature.length > 0) {
            return (
                <div className="feature-container">
                    {featureIcon && <img src={featureIcon.Url} alt={featureIcon.Name} className='tw-inline-block' width='20' height='20' />}
                    {feature}
                </div>
            );
        }
        return null;
    }

    return (
        <WidgetWrapper data={data}>
            {Title && <h2>{Title}</h2>}
            {SubTitle && <h3>{SubTitle}</h3>}
            {Content && <div dangerouslySetInnerHTML={{ __html: Content }} />}
            <div className={gridClasses}>
                {FeaturedItems && FeaturedItems.map((item, index) => (
                    <div key={index} className="card">
                        <a href={item.PropertyUrl} className="image-link">
                            <img src={item.Image.Url} alt={item.Image.Name} className="gallery-image" />
                        </a>
                        <div className="body tw-p-5">
                            <h4>{item.Title}</h4>
                            {renderFeature(item.FeatureOne, item.FeatureIcon)}
                            {renderFeature(item.FeatureTwo, item.FeatureIcon)}
                            {renderFeature(item.FeatureThree, item.FeatureIcon)}
                            {item.Price && <div className="price">{item.Price}</div>}
                            {item.Notes && <div className="notes">{item.Notes}</div>}
                            {item.PropertyUrl && <a href={item.PropertyUrl} className="n-button">View Property</a>}
                        </div>
                    </div>
                ))}
            </div>
        </WidgetWrapper>
    );
}

export default GalleryWidget;