import React, { useState, useEffect } from 'react';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CarouselWidget.scss'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import styles from './CarouselWidget.module.css';

const CarouselWidget = ({ data }) => {
    const { Title, SubTitle, Content, FeaturedItems } = data;
    const [gridClasses, setGridClasses] = useState('sm:tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4 tw-gap-y-6');

    useEffect(() => {
        if (FeaturedItems) {
            const itemsCount = FeaturedItems.length;
            let itemsInRow;
            if (itemsCount > 4) {
                itemsInRow = 1;
            } else {
                itemsInRow = itemsCount;
            }
            setGridClasses(`tw-grid sm:tw-grid-cols-1 md:tw-grid-cols-${itemsInRow} lg:tw-grid-cols-${itemsInRow} tw-gap-4 tw-gap-y-6`);
        }
    }, [FeaturedItems]);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
         slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <WidgetWrapper data={data}>
            <h2>{Title}</h2>
            <h3>{SubTitle}</h3>
            <div dangerouslySetInnerHTML={{ __html: Content }} />

            {(FeaturedItems && FeaturedItems.length > 4) ? (
                <Slider {...settings}>
                    {FeaturedItems.map((item, index) => (
                        <div key={index} className="image-container tw-mt-8 !tw-pr-5">
                            
                                {(item.Image && item.Image.Url) ? (
                                    <a href={item.PropertyUrl} className='tw-no-underline !tw-text-black'>
                                        <img src={item.Image.Url} alt={item.Image.Name} className="image" />
                                    </a>
                                ) : (
                                    <span className="image-placeholder">Image Not Available</span>
                                )}
                                <h4 className="tw-mt-8 tw-uppercase">{item.Title}</h4>
                                <p>{item.FeatureOne}</p>
                                <p>{item.FeatureTwo.substring(0, 120)}...</p>
                            <a href={item.PropertyUrl} className="more-button tw-uppercase tw-text-secondary">Read More</a>
                           
                        </div>
                    ))}
                </Slider>
            ) : (
                <div className={gridClasses}>
                    {FeaturedItems.map((item, index) => (
                        <a href={item.PropertyUrl} key={index} className="card">
                            <div className="image-container">
                                {(item.Image && item.Image.Url) ? (
                                    <img src={item.Image.Url} alt={item.Image.Name} className="image" />
                                ) : (
                                    <span className="image-placeholder">Image Not Available</span>
                                )}
                                <h4 className="image-title">{item.Title}</h4>
                            </div>
                        </a>
                    ))}
                </div>
            )}
        </WidgetWrapper>
    );
}

// Custom Arrow components
function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} ${styles.customArrow} ${styles.nextArrow}`}
            style={{ ...style, display: "block" }}  // Custom styling if needed
            onClick={onClick}
        >
            <FontAwesomeIcon
                icon={faChevronRight}
                style={{
                    color: "#ccc",
                    fontSize: "50px"
                }}
            />
        </div>
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} ${styles.customArrow} ${styles.prevArrow}`}
            style={{ ...style, display: "block" }}  // Custom styling if needed
            onClick={onClick}
        >
            <FontAwesomeIcon
                icon={faChevronLeft}
                style={{
                    color: "#ccc",
                    fontSize: "50px"
                }}
            />
        </div>
    );
}

export default CarouselWidget;


 