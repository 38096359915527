import React from 'react';
import { BsExclamationCircle } from 'react-icons/bs';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';

const AnnouncementWidget = ({ data }) => {
    return (
        <WidgetWrapper data={data}>
            <div className="tw-shadow-lg tw-border-l-4 tw-border-primary tw-rounded-md tw-p-5 tw-bg-white tw-flex">
                <BsExclamationCircle className="tw-mr-3 tw-text-primary tw-text-5xl" />
                <div dangerouslySetInnerHTML={{ __html: data.AnnouncementContent }} />
            </div>
        </WidgetWrapper>
    );
};

export default AnnouncementWidget;