import Skeleton from "react-loading-skeleton";
import SkeletonSearchForm from 'components/SkeletonSearchForm/SkeletonSearchForm';
import SkeletonProperty from 'components/SkeletonProperty/SkeletonProperty';

const SkeletonLocation = () => {

    return (
        <>

            <div className={"tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-min-h-[50vh] tw-bg-[#f9f9f9]"}>

                <div className="tw-bg-[#fff] tw-h-[40px] tw-w-[30%] tw-shadow"></div>
                <div className="tw-mt-5 tw-mb-5 tw-bg-[#fff] tw-h-[25px] tw-w-[40%] tw-shadow"></div>
                <div className="tw-px-3 tw-bg-[#fff] tw-mt-10">
                    <SkeletonSearchForm />
                </div>

            </div>
            <div className="tw-p-3">
                <div className="tw-grid tw-grid-cols-5 tw-gap-3">
                    <div className="tw-col-span-3 lg:tw-col-span-3">
                        <div>
                            <div className="tw-flex tw-justify-end tw-flex lg:tw-justify-between tw-bg-slate-100 tw-p-2 tw-content-center tw-mb-4 tw-h-[50px]"></div>
                            <div className="tw-grid sm:tw-grid-cols-1  md:tw-grid-cols-2 2xl:tw-grid-cols-3 tw-gap-4">
                                <SkeletonProperty />
                                <SkeletonProperty />
                                <SkeletonProperty />
                                <SkeletonProperty />
                                <SkeletonProperty />
                                <SkeletonProperty />
                            </div>
                        </div>

                    </div>
                    <div className="lg:tw-columns-1 tw-col-span-2 ">
                        <div className="tw-h-[100vh] tw-bg-[#eee]">

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default SkeletonLocation;
