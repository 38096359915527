import React, { useEffect, useState, useContext } from 'react';

import SocialNetworks from 'components/SocialNetworks/SocialNetworks';
import styles from './Footer.module.css';
import { SiteSettingsContext } from '../../contexts/SiteSettingsProvider';
import CookiePolicyMessage from './CookiePolicyMessage/CookiePolicyMessage';

const Footer = () => {
  const settings = useContext(SiteSettingsContext);
  const [ContactByTelephoneText, setContactByTelephoneText] = useState();
  const [ContactTelephone, setContactTelephone] = useState();
  const [ContactBySocialNetworksText, setContactBySocialNetworksText] = useState();

  const [col1Title, setCol1Title] = useState();
  const [col2Title, setCol2Title] = useState();

  const [cookiePolicyMessage, setCookiePolicyMessage] = useState();

  useEffect(() => {
     
    if (settings){
      setContactByTelephoneText(settings.FooterTexts.ContactByTelephoneText);
      setContactTelephone(settings.FooterTexts.ContactTelephone);
      setContactBySocialNetworksText(settings.FooterTexts.ContactBySocialNetworksText);

      setCol1Title(settings.FooterTexts.FooterColumn1Title);
      setCol2Title(settings.FooterTexts.FooterColumn2Title);

      setCookiePolicyMessage(settings.FooterTexts.CookiePolicyMessage);
    }
  }, [settings]); 

  let nav1Items;
  let nav2Items;
  if (settings) {
    let nav1 = settings.BotNav1;
    let nav2 = settings.BotNav2;

    if (nav1){
      nav1Items = nav1.map((item, index) =>
        <li key={index} className='tw-inline-block tw-pl-3'>
          <a href={item.Url}>
            {item.Name}
          </a>
        </li>
      );
    }

    if (nav2){
      nav2Items = nav2.map((item, index) =>
        <li key={index} >
          <a href={item.Url}>
            {item.Name}
          </a>
        </li>
      );
    }
  }

 
  if (settings){
    return (

      <>
        <div className={styles.Footer + " tw-py-5 tw-mt-5 tw-bg-primary_dark tw-text-white tw-hidden"}>
          <div className="top-footer tw-border-b tw-border-zinc-600 tw-py-3">
            <div className="container">
              <div className="tw-grid md:tw-grid-cols-2 tw-mb-6 tw-grid-cols-auto">
                <div className="tw-text-2xl">
                  {ContactByTelephoneText}
                  {ContactTelephone}
                </div>
                <div className="tw-flex md:tw-justify-end tw-text-2xl tw-mt-4 md:tw-mt-0">
                  <span className="tw-mr-3">{ContactBySocialNetworksText}</span> {settings !== "" && <SocialNetworks socialNetworks={settings.SocialNetworks} wrapperclassName="tw-flex tw-gap-3 tw-justify-center md:tw-justify-end" />}
                </div>
              </div>
            </div>
          </div>
          <div className="mid-footer tw-border-b tw-border-zinc-600 tw-py-8">
            <div className="container">
              <div className="tw-grid md:tw-grid-cols-4">
                <div className="">
                  <h2>{col1Title}</h2>
                  <ul className="tw-p-0 tw-m-0">
                    {nav1Items}
                  </ul>
                </div>
                <div className="">
                  <h2>{col2Title}</h2>
                  <ul className="tw-p-0 tw-m-0">
                    {nav2Items}
                  </ul>
                </div>
                <div className="">
                  {settings !== "" && <span dangerouslySetInnerHTML={{ __html: settings.FooterTexts.FooterColumn3Text }}></span>}

                </div>
                <div className="">
                  {settings !== "" && <span dangerouslySetInnerHTML={{ __html: settings.FooterTexts.FooterColumn4Text }}></span>}
                </div>
              </div>
            </div>
          </div>
          <div className="bottom-footer tw-text-center tw-pt-8 tw-pb-3">
            <div className="container">
              {settings !== "" && <span dangerouslySetInnerHTML={{ __html: settings.FooterTexts.FooterBottom1Text }}></span>}
              {settings !== "" && <div>
                <span>Copyright © {new Date().getFullYear()}</span> <span dangerouslySetInnerHTML={{ __html: settings.FooterTexts.FooterBottom2Text }}></span>
              </div>}
              <div><a href="https://www.ipro-software.com/web-design/" className="developed_by" alt="Website builder for your short stay agency" title="Mobile ready websites built specifically for the vacation rental industry" rel="noopener" target="_blank"  >Design by iPro Vacation Rental Websites</a></div>
            </div>
          </div>

        </div>

        <footer className="tw-bg-[#f8f8f8] tw-py-10">
          <div className="tw-container tw-mx-auto tw-px-4 tw-flex tw-flex-wrap tw-justify-between">
            {/* Logo and Name */}
            
            <div className="tw-mb-6 tw-flex tw-flex-col tw-justify-center tw-items-start">
              <img src={`${settings.SiteSettings.DesktopLogo}`} alt="Logo" className="tw-mb-2" />
              {/* <p className="tw-font-semibold tw-text-lg tw-m-0">A PLACE LIKE HOME</p> */}
            </div>

            {/* Address Column */}
            <div className="tw-mb-6">
              <p className="tw-font-bold tw-m-0">ADDRESS</p>
              {/* Individual address lines without default margins */}
              <div className="tw-space-y-1">
                {settings !== "" && <span dangerouslySetInnerHTML={{ __html: settings.FooterTexts.FooterColumn3Text }} className='tw-text-gray-500 tw-text-sm'></span>}
              </div>
              <p className="tw-font-bold tw-m-0 tw-mt-4">CALL US</p>
              <p className="tw-m-0 tw-text-gray-500 tw-text-sm">{ContactTelephone}</p>
            </div>

            {/* Contact Column */}
            <div className="tw-mb-6">
              <p className="tw-font-bold tw-m-0">EMAIL US</p>
              <p className="tw-m-0 tw-text-gray-500 tw-text-sm">{settings.SiteSettings.ContactEmail}</p>
              <p className="tw-font-bold tw-mt-4 tw-m-0 tw-mt-4">WHATSAPP US</p>
              <p className="tw-m-0 tw-text-gray-500 tw-text-sm">{settings.SiteSettings.ContactMobilePhone}</p>
              <p className="tw-font-bold tw-m-0 tw-mt-4 tw-mb-2">CONNECT WITH US</p>
              {settings !== "" && <SocialNetworks linkClass={`tw-inline-block`} className='tw-inline-block' color={`#e1cba6`} iconSize="30px" socialNetworks={settings.SocialNetworks} wrapperclassName="tw-flex tw-gap-3 tw-justify-center md:tw-justify-end" />}
            </div>

            {/* Social Links and Newsletter Column */}
            <div className="tw-mb-6">
              
              
              <div className="tw-mb-4">
                <p className="tw-font-bold tw-m-0 tw-mb-2">STAY UPDATED:</p>
                <input type="email" placeholder="Email" className="tw-border tw-border-2 tw-border-gray-200 tw-w-full tw-p-3 tw-mr-2 tw-outline-none" />
                {/* <button className="tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white">Sign Up</button> */}
              </div>
              <ul className="tw-p-0 tw-m-0">
                {nav2Items}
              </ul>
            </div>
          </div>

          <div className='tw-border-t tw-border-gray-300'>
            <div className="tw-container tw-mx-auto  tw-pt-4 tw-mt-4 tw-flex tw-justify-between tw-px-4">
              <p className="tw-text-sm tw-m-0">© COPYRIGHT {new Date().getFullYear()} A PLACE LIKE HOME</p>
              <div className="tw-text-sm">

                <ul className="tw-p-0 tw-m-0">
                  {nav1Items}
                </ul>
              </div>
            </div>
          </div>

        </footer>


        {/* <CookiePolicyMessage text={cookiePolicyMessage} /> */}

      </>
    );
  }

};

 

export default Footer;
