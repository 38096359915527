import React, { useEffect, useState, useRef } from 'react';
import styles from './SearchResultMap.module.css';
 
import MapPoint from './MapPoint/MapPoint';
 
import OverlayContainer from "./OverlayContainer/OverlayContainer";

 
const SearchResultMap = (props) => {

  const ref = useRef(null);
  const [map, setMap] = useState();
 
  const [markersData, setMarkersData] = useState([]);
  const [zoom, setZoom] = useState(4);
  const [center, setCenter] = useState();
  const [isLoading, setIsLoading] = useState(true); 

 

  useEffect(() => {

      
    
      let markerBounds = new window.google.maps.LatLngBounds();
      let randomPoint, i;
      if (markersData.length > 0) {
        for (i = 0; i < markersData.length; i++) {
          randomPoint = new window.google.maps.LatLng(markersData[i].Latitude, markersData[i].Longitude);
          markerBounds.extend(randomPoint);
          if (i === markersData.length - 1) {
            map.fitBounds(markerBounds);
          }
        }
      }

 
      {/*const restrictions = {
        north: markerBounds.Wa.hi,
        south: markerBounds.Wa.lo,
        west: markerBounds.Ia.hi,
        east: markerBounds.Ia.lo,
      }; */}

 
       
      if (ref.current) {
       
        let createdMap = new window.google.maps.Map(
          ref.current,
          {
            center: { lat: 55.753559, lng: 37.609218 },
            zoom: zoom,
            //minZoom: zoom + 1,
            //maxZoom: zoom + 12,
            gestureHandling: 'greedy',
            bounds: markerBounds,
            disableDefaultUI: true,
            clickableIcons: true
          }
        );
        setMap(createdMap);
        setIsLoading(true); 

        createdMap.fitBounds(markerBounds);

        setMap(createdMap);

        setTimeout(() => {
          createdMap.setZoom(createdMap.getZoom() - 1);
        }, 1);

        setMarkersData(props.mapData);

      };

 
  
  }, [center, zoom, markersData, props.mapData]);
 
 

  const markers = markersData.map(function (apartment, index) {
    const { ...info } = apartment;
    
    return map && (
      <OverlayContainer
        key={index}
        map={map}
        position={{
          lat: apartment.Latitude,
          lng: apartment.Longitude
        }}
      >
        <MapPoint {...info} />
      </OverlayContainer>
    );
  });
 
  
  return (
 
    <div ref={ref} id="map"
      className={styles.SearchResultMap}
      style={{ height: '100vh', width: '100%' }}
    >
      {markers}
    </div>
 
  );
};

 

SearchResultMap.defaultProps = {};

export default SearchResultMap;
