import React from 'react';

const WidgetWrapper = ({ data, children }) => {

    const backgroundImageUrl = data.BackgroundImage?.Url || data.BackgroundImage;

    const outerBackgroundStyle = data.UseFullScreenWidthForWidgetcContent
        ? { backgroundColor: data.BackgroundColor, backgroundImage: `url(${backgroundImageUrl})` }
        : {};

    const innerBackgroundStyle = ( data.UseFullScreenWidthForWidgetcContent)
        ? {}
        : { backgroundColor: data.BackgroundColor, backgroundImage: `url(${backgroundImageUrl})` };

    

    // Apply 'tw-p-3' class if BackgroundColor or BackgroundImage exists
    const paddingClass = (data.BackgroundColor || backgroundImageUrl) ? 'tw-p-5' : '';

    const bgClass = Boolean(data.BackgroundImage?.Url || data.BackgroundImage) ? 'tw-text-white' : '';
 
    return (
        <div style={outerBackgroundStyle} className={`${bgClass} tw-my-5 tw-p-5 tw-bg-cover tw-bg-no-repeat`} id={`widget-${data.WidgetId}`}>
            <div style={innerBackgroundStyle}
                className={`${data.UseFullScreenWidthForThisWidget ? 'tw-bg-cover tw-bg-no-repeat' : 'tw-container tw-m-auto '} ${paddingClass}`}>
                {children}
            </div>
        </div>
    );
};

export default WidgetWrapper;