import React from 'react';
import PropTypes from 'prop-types';
import styles from './PropertyPrice.module.css';
import moment from 'moment';
 

const PropertyPrice = ({ item, dictionary }) => {
  let priceToken;
  if (dictionary){
    priceToken = dictionary[item.WhatPriceTokenToUse];
  }


  
  let price = item.Price;
  let start = moment(item.SearchStart);  
  let end = moment(item.SearchEnd);
  let nights = +end.diff(start , 'days') || 7;
  let tokens = ['nights', 'price', 'date' ]; // tokens
  let tokensVars = [nights, price, start]; // vars for tokens with same name 
  if (dictionary === 'night') return  <div className="tw-font-bold tw-text-[1.2rem]">{price} / night</div>
  if (priceToken === undefined) return < div className="tw-font-bold tw-text-[1.2rem]" >{price}</div >
  let string = priceToken.split(' ');
  for (var i = 0; i < string.length; i++) {
    for (let a = 0; a < tokens.length; a++) {
      if (tokens.includes(string[i])) {    
        priceToken = priceToken.replace("{" + tokens[a] + "}", '<span className="tokensVars[a]">' + tokensVars[a] +  '</span>');
      }
    } 
 
  }

  return (
    <div className={styles.PropertyPrice}> 
      <div className="tw-font-bold tw-text-[1.2rem]" dangerouslySetInnerHTML={{ __html: priceToken }} ></div>
    </div>
  )
};

PropertyPrice.propTypes = {};

PropertyPrice.defaultProps = {};

export default PropertyPrice;
