import React from 'react';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';

const FeaturedContentWidget = ({ data }) => {

    const alignmentClass = data.AlignImageRight ? "lg:tw-flex-row-reverse" : "lg:tw-flex-row";

    return (
        <WidgetWrapper data={data}>
            <div className={`tw-p-5 tw-shadow-lg tw-rounded-md tw-flex tw-flex-col ${alignmentClass}`}>
                {data.Image ? (
                    <>
                        <img className="tw-w-full lg:tw-w-1/2" src={data.Image.Url} alt="" />
                        <div className="tw-w-full lg:tw-w-1/2 tw-flex tw-flex-col tw-justify-center tw-px-5">
                            <h2 className="tw-text-2xl tw-mb-3">{data.Label}</h2>
                            <div dangerouslySetInnerHTML={{ __html: data.Content }} />
                            {data.Url && <a href={data.Url} className="tw-mt-3 tw-bg-blue-500 tw-text-white tw-py-2 tw-px-4 tw-rounded">{data.Dictionaries.FindOutMore}</a>}
                        </div>
                    </>
                ) : (
                    <div className="tw-w-full tw-flex tw-flex-col tw-justify-center tw-px-5">
                        <h2 className="tw-text-2xl tw-mb-3">{data.Label}</h2>
                        <div dangerouslySetInnerHTML={{ __html: data.Content }} />
                        {data.Url && <a href={data.Url} className="tw-mt-3 tw-bg-blue-500 tw-text-white tw-py-2 tw-px-4 tw-rounded">{data.Dictionaries.FindOutMore}</a>}
                    </div>
                )}
            </div>
        </WidgetWrapper>
    );
};

export default FeaturedContentWidget;