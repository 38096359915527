import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaStar } from 'react-icons/fa';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';
import SwiperCore, { Pagination, Navigation } from 'swiper/core';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import './TestimonialsWidget.scss';


// Install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const TestimonialsWidget = ({ data }) => {
    console.log(data.Testimonials);
    return (
        <WidgetWrapper data={data}>
            <div className="tw-p-5 tw-shadow-lg tw-rounded-md TestimonialsWidget">
                <h2 className="tw-text-2xl tw-mb-1">{data.Title}</h2>
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    autoHeight={true}
                    autoplay={{ delay: 3000 }} 
                    pagination={{ clickable: true, dynamicBullets: true }}
                    
                >
                    {data.Testimonials.map((testimonial, index) => (
                        <SwiperSlide key={index}>
                            <div className="tw-flex tw-flex-col">
                                <h2 className="tw-text-lg tw-mb-0">{testimonial.ReviewTitle}</h2>
                                <h3 className="tw-mb-0">{testimonial.ReviewSubTitle}</h3>
                                <div className="tw-flex tw-mb-1">
                                    {Array.from({ length: 5 }, (_, i) => (
                                        <FaStar
                                            key={i}
                                            className={
                                                i < Number(testimonial.RatingScore[0])
                                                    ? 'tw-text-yellow-500'
                                                    : 'tw-text-gray-300'
                                            }
                                        />
                                    ))}
                                </div>
                                <p dangerouslySetInnerHTML={{ __html: testimonial.ReviewDetail }}></p>
                                <p className="tw-mt-3 tw-text-sm tw-italic ">- {testimonial.GuestName}</p>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
 
            </div>
        </WidgetWrapper>
    );
};

export default TestimonialsWidget;