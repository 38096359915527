import React, { useEffect, useState } from 'react';

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const useGetUrlParams = (params) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const paramsCapital = capitalizeFirstLetter(params);
        const resList = urlParams.getAll(paramsCapital) || urlParams.getAll(params);
        const res = urlParams.get(paramsCapital) || urlParams.get(params);

        setData(resList.length > 1 ? resList : res || '');
    }, [params]);

    return [data];
};

export default useGetUrlParams;
 

