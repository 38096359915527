import React, { useEffect } from 'react';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';

const EmbedCodeWidget = ({ data }) => {
    useEffect(() => {
        if (data.EmbedCode && data.EmbedCode.includes('<script')) {
            // create a new DOMParser
            const parser = new DOMParser();

            // use the DOMParser to turn the EmbedCode into a Document
            const doc = parser.parseFromString(data.EmbedCode, 'text/html');

            // get the script element from the Document
            const scriptElement = doc.querySelector('script');

            if (scriptElement && scriptElement.src) {
                // create a new script element
                const script = document.createElement('script');

                // set the src attribute
                script.src = scriptElement.src;

                // set the async attribute if it is present
                if (scriptElement.async !== null) {
                    script.async = true;
                }

                // append the script to the body of the document
                document.body.appendChild(script);

                // remove the script on component unmount
                return () => {
                    document.body.removeChild(script);
                };
            }
        }
    }, [data]);

    return (
        <WidgetWrapper data={data}>
            {data.EmbedTitle && <h3>{data.EmbedTitle}</h3>}
            {/* Here we remove the script tag from the EmbedCode */}
            {data.EmbedCode && <div dangerouslySetInnerHTML={{ __html: data.EmbedCode.replace(/<script[^>]*>([\s\S]*?)<\/script>/gi, '') }} />}
        </WidgetWrapper>
    )
};

export default EmbedCodeWidget;