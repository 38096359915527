import React from 'react';
import styles from './SearchControlPanel.module.css';
import MapSwitcher from 'components/SearchResult/SearchControlPanel/MapSwitcher/MapSwitcher';
import SearchSorter from 'components/SearchResult/SearchControlPanel/SearchSorter/SearchSorter';

const SearchControlPanel = ({ upMapState, updateSorting }) => {
 

  return (
    <div id="SortPanel" className={styles.SearchControlPanel + " tw-flex tw-justify-end tw-flex lg:tw-justify-between tw-bg-slate-100 tw-p-2 tw-content-center tw-mb-4"}>

      <MapSwitcher upMapState={upMapState} shown={true} text="Hide Map" />
      <SearchSorter updateSorting={updateSorting} />
    </div>
  )
};

SearchControlPanel.propTypes = {};

SearchControlPanel.defaultProps = {};

export default SearchControlPanel;