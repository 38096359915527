import React, { useEffect, useState, useContext } from 'react';
import { Link, Element, Events } from 'react-scroll';
import { CurrencyContext } from '../../contexts/SiteSettingsProvider';
import useWindowSize from "utils/useWindowSize";
import "tailwindcss/tailwind.css";
import CalendarDataContext from '../../contexts/CalendarDataContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faList, faCalendar, faLocationCrosshairs, faDollarSign, faHandshake } from '@fortawesome/pro-light-svg-icons';
import { faStar} from '@fortawesome/pro-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './property.css';
 
import ImageSlider from '../../components/PropertyPage/ImageSlider/ImageSlider';
import BookingForm from '../../components/PropertyPage/BookingForm/BookingForm';
import BookingFormMobile from '../../components/PropertyPage/BookingFormMobile/BookingFormMobile';

import Description from '../../components/PropertyPage/Description/Description';
import Amenities from '../../components/PropertyPage/Amenities/Amenities';
import Rates from '../../components/PropertyPage/Rates/Rates';
import AvailabilityCalendar from '../../components/PropertyPage/AvailabilityCalendar/AvailabilityCalendar';
import BreadCrumbs from '../../components/PropertyPage/BreadCrumbs/BreadCrumbs';
 
import Map from '../../components/PropertyPage/Map/Map';
import Reviews from '../../components/PropertyPage/Reviews/Reviews';
import MainAttrs from '../../components/PropertyPage/MainAttrs/MainAttrs';
import EnquiryForm from '../../components/PropertyPage/EnquiryForm/EnquiryForm';

const icons = {
    Description: faHome,
    Amenities: faList,
    Rates: faDollarSign,
    Availability: faCalendar,
    Map: faLocationCrosshairs,
    Reviews: faStar,
    Policies: faHandshake
};

const MenuItem = ({ name, to, isActive }) => {
    return (
        <Link
            activeclassName="tw-border-b-2 tw-border-secondary"
            to={to}
            spy={true}
            smooth={true}
            duration={300}
            className={`tw-text-black tw-font-normal tw-uppercase tw-cursor-pointer tw-px-4 tw-font-bold tw-no-underline tw-py-2 tw-flex tw-items-center ${isActive ? "tw-border-b-2 tw-border-secondary" : ""}`}
        >
            <FontAwesomeIcon icon={icons[name]} className="tw-mr-2 md:tw-hidden" />
            <span className="tw-hidden lg:tw-inline">{name}</span>
        </Link>
    );
};

const Property = ({ pageId, keyID, GoogleMapAPIKey }) => {
    const [activeMenuItem, setActiveMenuItem] = useState("Description");
    const [isLoading, setIsLoading] = useState(true);
    const windowSize = useWindowSize();
    const [currency, setCurrency] = useContext(CurrencyContext);
    const [calendarData, setCalendarData] = useState([]);
    const [isMobile, setIsMobile] = useState(false);

    const [propertyData, setPropertyData] = useState({});
    const [selectedDates, setSelectedDates] = useState({ startDate: null, endDate: null });
    const [images, setImages] = useState([]);
    const [reviews, setReviews] = useState([]);

    const [pageH1Title, setPageH1Title] = useState('');
    const [intro, setIntro] = useState('');
    const [description, setDescription] = useState('');
    const [propertyRates, setPropertyRates] = useState([]);
    const [geoLocation, setGeoLocation] = useState([]);
    const [distances, setDistances] = useState([]);
    const [locationDescription, setLocationDescription] = useState('');
    const [propertyAttributes, setPropertyAttributes] = useState({});
    const [floorPlan, setFloorPlan] = useState('');
    

    const [cons, setCons] = useState('');
    const [pros, setPros] = useState('');

    const [maxOccupancy, setMaxOccupancy] = useState(1);
    const [maxPets, setMaxPets] = useState(1);
    const [currencySymbol, setCurrencySymbol] = useState("£");
    const [hideCalendar, setHideCalendar] = useState(false);
    const [disableAvailability, setDisableAvailability] = useState(false);
    const [hideRates, setHideRates] = useState(false);
    const [plotSize, setPlotSize] = useState(null);
    const [propertyLicence, setPropertyLicence] = useState('');
    const [propertyReference, setPropertyReference] = useState('');
    const [regionDescription, setRegionDescription] = useState('');
    const [propertyRentalNotes, setPropertyRentalNotes] = useState('');
    const [virtualTour, setVirtualTour] = useState('');
    const [virtualTourTitle, setVirtualTourTitle] = useState('');
    const [breadcrumbs, setBreadcrumbs] = useState(null);
    const [propertyMinRate, setPropertyMinRate] = useState(null);
    const [propertyMaxRate, setPropertyMaxRate] = useState(null);

    const [showEnquire, setShowEnquire] = useState(false);
    const handleCloseEnquire = () => setShowEnquire(false);
    const handleShowEnquire = () => setShowEnquire(true);

    useEffect(() => {
        if (windowSize.width > 800) {
            setIsMobile(false);
        }

        if (windowSize.width < 800) {
            setIsMobile(true);
        }
    }, [windowSize.width]);

 
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BASE_URL}apis/propertypage?pageid=${pageId}&Currency=${currency}`) //${currency}`)
            .then(response => response.json())
            .then(data => {
                setPropertyData(data.Property.Details)
                setIntro(data.Property.Details.Intro);
                setCons(data.Property.Details.Cons);
                setPros(data.Property.Details.Pros);
                setPageH1Title(data.Property.Details.PageH1Title);
                setReviews(data.Property.Details.Reviews);
                setDescription(data.Property.Details.MainDescription);
                setImages(data.Property.Details.SliderImages);
                setCalendarData(data.Property.Details.Calendar);
                setFloorPlan(data.Property.Details.FloorPlan);

                

                setPropertyRates(data.Property.Details.PropertyRates);
                setPropertyAttributes(data.Property.Details.PropertyAttributes);
                setGeoLocation(data.Property.Details.GeoLocation);
                setDistances(data.Property.Details.Distances);
                setLocationDescription(data.Property.Details.LocationDescription);
                 
                setIsLoading(false);  // Data has been fetched successfully

                setMaxOccupancy(data.Property.Details.MaxOccupancy);
                setMaxPets(data.Property.Details.MaxPets);
                setCurrencySymbol(data.Property.Details.CurrencySymbol);
                setHideCalendar(data.Property.Details.HideCalendar);
                setDisableAvailability(data.Property.Details.DisableAvailability);
                setHideRates(data.Property.Details.HideRates);
                setPlotSize(data.Property.Details.PlotSize);
                setPropertyLicence(data.Property.Details.PropertyLicence);
                setPropertyReference(data.Property.Details.PropertyReference);
                setRegionDescription(data.Property.Details.RegionDescription);
                setPropertyRentalNotes(data.Property.Details.PropertyRentalNotes);
                setVirtualTour(data.Property.Details.VirtualTour);
                setVirtualTourTitle(data.Property.Details.VirtualTourTitle);
                setBreadcrumbs(data.Settings.BreadCrumbs);
                setPropertyMinRate(data.Property.Details.PropertyMinRate);
                setPropertyMaxRate(data.Property.Details.PropertyMaxRate);
            })
            .catch(error => {
                console.error('Error fetching blocked dates:', error);
                setIsLoading(false);  // Data has been fetched successfully
            });
    }, [currency]);


 

    useEffect(() => {
        Events.scrollEvent.register('begin', (to, element) => {
            console.log('begin', to, element);
        });

        Events.scrollEvent.register('end', (to, element) => {
            console.log('end', to, element);
        });

        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);


    const handleDateChange = (startDate, endDate) => {
        setSelectedDates({ startDate, endDate });
    };

    return (
        <>
 
            <div className="tw-w-full tw-overflow-hidden">
                <ImageSlider images={images} isLoading={isLoading} />
            </div>

            <Modal show={showEnquire} onHide={handleCloseEnquire}>
                <Modal.Header closeButton>
                    <Modal.Title>Enquire</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EnquiryForm
                        pageId={pageId}
                        currencySymbol={currency}
                    />
                </Modal.Body>
            </Modal>            

            <div className="tw-max-w-[1280px] tw-mx-auto tw-py-2">

                <div className={`tw-w-full tw-grid tw-grid-cols-12 tw-gap-4 tw-mt-2 ${windowSize.width < 1024 ? 'tw-grid-flow-row-dense' : ''}`}>
                    {/* Sidebar */}
                    <div className={`tw-col-span-12 ${windowSize.width >= 1024 ? 'lg:tw-col-span-4 tw-order-last' : 'tw-order-first'}`}>
                        <div className="tw-sticky tw-top-0 tw-z-50 lg:tw-h-[120vh] tw-pb-5">

                           {!isMobile && 
                                <>
                                <div className="tw-white tw-shadow-xl tw-mt-3 tw-pb-3">
                                        <CalendarDataContext.Provider value={calendarData} isLoading={isLoading}>
                                            {<BookingForm
                                                selectedDates={selectedDates}
                                                onDateChange={handleDateChange}
                                                maxOccupancy={maxOccupancy}
                                                maxPets={maxPets}
                                                currencySymbol={currency}
                                                pageId={pageId}
                                                disableAvailability={disableAvailability}
                                            />}
                                        </CalendarDataContext.Provider>
                                        <div
                                            onClick={handleShowEnquire}
                                            className="hover:tw-text-[#272727] tw-cursor-pointer tw-m-5 tw-uppercase tw-border tw-border-black tw-text-black tw-no-underline tw-py-2 tw-px-4 tw-rounded tw-block tw-text-center tw-mt-3 tw-transition tw-duration-200"
                                        >
                                            Make An Enquiry
                                        </div>
                                    </div>
 
                                </>
                            } 


                            
                           {isMobile && 
                                <CalendarDataContext.Provider value={calendarData} isLoading={isLoading}>
                                    {<BookingFormMobile
                                        selectedDates={selectedDates}
                                        onDateChange={handleDateChange}
                                        maxOccupancy={maxOccupancy}
                                        maxPets={maxPets}
                                        currencySymbol={currencySymbol}
                                        pageId={pageId}
                                        disableAvailability={disableAvailability}
                                        propertyMaxRate={propertyMaxRate}
                                        propertyMinRate={propertyMinRate}
                                        setShowEnquire={setShowEnquire}
                                    />}
                                </CalendarDataContext.Provider>
                            }  
 
                        </div>

               
                    </div>
                    {/* Main content */}
                    <div className={`tw-col-span-12 ${windowSize.width >= 1024 ? 'lg:tw-col-span-8 tw-order-first' : 'tw-order-last tw-m-5'}`}>

                        <h1 className="tw-text-3xl tw-mb-0">{pageH1Title}</h1>  
                        
                        <MainAttrs propertyAttributes={propertyAttributes} />  

                        <div className="tw-sticky tw-top-0 tw-bg-white tw-w-full tw-flex tw-justify-between tw-mt-5 tw-border-b">
                            <MenuItem name="Description" to="description" isActive={activeMenuItem === "Description"} />
                            <MenuItem name="Amenities" to="amenities" isActive={activeMenuItem === "Amenities"} />
                            <MenuItem name="Policies" to="rates" isActive={activeMenuItem === "Rates"} /> 
                            {!hideCalendar && <MenuItem name="Availability" to="availability" isActive={activeMenuItem === "Availability"} />}
                            <MenuItem name="Map" to="map" isActive={activeMenuItem === "Map"} />
                            <MenuItem name="Reviews" to="reviews" isActive={activeMenuItem === "Reviews"} />
                        </div>
                        

                        <div className="  ">
                            {/* {(breadcrumbs && breadcrumbs.length > 0) && <BreadCrumbs data={breadcrumbs} />} */}
                           
                            {/* Sections */}
                           
                            <Element name="description" className="property-section" >
                                
                                
                                
                                {(propertyData.PropertyCountry && propertyData.PropertyCity) &&
                                    <div className=" tw-flex tw-items-center tw-py-1">
                                        <FontAwesomeIcon icon={faLocationCrosshairs} className="tw-mr-2" />
                                        <span className="tw-mr-2">{propertyData.PropertyCountry}</span>
                                        <span className="tw-mr-2">|</span>
                                        <span className="tw-mr-2">{propertyData.PropertyCity}</span>
                                    </div>
                                }  

                                <div>
                                    {propertyData.Reviews && propertyData.Reviews.length > 0 && (
                                        <div>Our Rating: <FontAwesomeIcon icon={faStar} style={{ color: 'gold' }} className="tw-mr-1" />
                                            {(
                                                propertyData.Reviews.reduce((acc, review) => acc + review.Rating, 0) / propertyData.Reviews.length
                                            ).toFixed(1)}</div>
                                    )}
                                </div> 

                               
                                
                                <Description intro={intro} description={description} cons={cons} pros={pros} isLoading={isLoading} />
                            
                                {virtualTour && 
                                   <>
                                        { virtualTourTitle }
                                        <div className="virtualTour" dangerouslySetInnerHTML={{ __html: virtualTour }} />
                                   </>
                                }
                                {floorPlan && <div className="floorPlan" dangerouslySetInnerHTML={{ __html: floorPlan }} />}
                                {plotSize && <div className="plotSize" dangerouslySetInnerHTML={{ __html: plotSize }} />} 
                                {propertyLicence && 
                                <>
                                    <b>Property Licence: </b>
                                    <span className="propertyLicence tw-ml-2" dangerouslySetInnerHTML={{ __html: propertyLicence }} />
                                </>} 
                                {propertyReference && <div className="propertyReference "><b>Property Reference:</b> {propertyReference}</div>}
                            </Element>

                            <Element name="amenities" className="property-section">
                                <Amenities propertyAttributes={propertyAttributes} isLoading={isLoading} />
                            </Element>

                           
                                <Element name="rates" className="property-section">
                                    
                                    {/* {!hideRates && <Rates rates={propertyRates} isLoading={isLoading} />} */}
                                    <div className="table-responsive">
                                        {propertyRentalNotes ?  
                                        <div className="propertyRentalNotes" dangerouslySetInnerHTML={{ __html: propertyRentalNotes }} /> :
                                        <>
                                        

                                            <section className="policies">
                                                <article className="policy">
                                                    <h5>Cancellation Policy</h5>
                                                    <p>Provided you book more than 7 days before the start of your stay, you can cancel your booking within 24 hours and we'll provide a full refund.</p>
                                                </article>

                                                <article className="policy">
                                                    <h5>Check-In & Check-Out Policy</h5>
                                                    <p>We'll meet you at the home at your convenience any time after 4PM on your arrival date, and our standard check-out time is 11AM. Early check-in and late check-out can be arranged in advance, subject to availability and a charge.</p>
                                                </article>

                                                <article className="policy">
                                                    <h5>Lead Guest Requirement</h5>
                                                    <p>The lead guest must be at least 25 years of age.</p>
                                                </article>
                                            </section>
                                        </>
                                    }   
                                    </div>
                                </Element>
                           

                            {!hideCalendar && 
                                <Element name="availability" className="property-section AvailabilityCalendar">
                                    <CalendarDataContext.Provider value={calendarData}>
                                        <AvailabilityCalendar selectedDates={selectedDates} onDateChange={handleDateChange} isLoading={isLoading} disableAvailability={disableAvailability} />
                                    </CalendarDataContext.Provider>

                                </Element>
                           }
                      
                           
                            <Element name="map" className="property-section">
                                <Map geoLocation={geoLocation} GoogleMapAPIKey={GoogleMapAPIKey} isLoading={isLoading} distances={distances} locationDescription={regionDescription} />
                            </Element>
                            <Element name="reviews" className="property-section">
                                <Reviews reviews={reviews} isLoading={isLoading} />
                            </Element>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Property;