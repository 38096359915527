import React from 'react';
import LocationPickerWidget from './LocationPickerWidget/LocationPickerWidget';
import LocationPickerWidget1 from './LocationPickerWidget1/LocationPickerWidget1';
import LocationPickerWidget2 from './LocationPickerWidget2/LocationPickerWidget2';
import LocationPickerWidget3 from './LocationPickerWidget3/LocationPickerWidget3';

const LocationPicker = ({ data }) => {
    const { Template } = data;

    switch (Template) {
        case 'TBS_Widget_Location_Picker':
            return <LocationPickerWidget data={data} />;
        case 'TBS_Widget_Location_Picker1':
            return <LocationPickerWidget1 data={data} />;
        case 'TBS_Widget_Location_Picker2':
            return <LocationPickerWidget2 data={data} />;
        case 'TBS_Widget_Location_Picker3':
            return <LocationPickerWidget3 data={data} />;
        default:
            return null;
    }
}

export default LocationPicker;