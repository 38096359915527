import React, { useState, useRef, useEffect } from 'react';
import PropertyCard from "../PropertyCard/PropertyCard"
import PropertyPoint from "../PropertyPoint/PropertyPoint"
import PropTypes from 'prop-types';
import styles from './MapPoint.module.css';

const MapPoint = (props) => {
  const [opened, setIsOpened] = useState(false);
  const handleOnOpen = (e) => {
    setIsOpened(true)
  }; 
  const handleOnClose = (e) => {
    setIsOpened(false)
  };
 
  const containerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpened(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef]);


  const { ...info } = props;
  return (
      <div className={styles.MapPoint + " tw-relative  "} ref={containerRef} >
        <span onClick={handleOnOpen}  ><PropertyPoint  {...info} ></PropertyPoint></span>
        {opened && <PropertyCard  item={props} handleOnClose={handleOnClose}></PropertyCard> }
      </div>
  );
};

MapPoint.propTypes = {};

MapPoint.defaultProps = {};

export default MapPoint;
