import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './PropertyInputSearch.module.css';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import useDetectOutsideClick from 'utils/useDetectOutsideClick'

function debounce(cb, interval, immediate) {
  var timeout;
  return function () {
    var context = this, args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) cb.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, interval);

    if (callNow) cb.apply(context, args);
  };
};

export const PropertyInputSearch = ({className}) => {

  let [results, setResults] = useState([]);
  let [words, setTerm] = useState('');
  let [message, setMessage] = useState('');
  let [dropDownVisability, setDropDownVisability] = useState(false);
  let [closeBtnVisability, setCloseBtnVisability] = useState(false);
  const dropdownRef = useRef(null);
  const input = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  

  let domain = process.env.REACT_APP_BASE_URL;
  let APIUrl = "ipro/webmethod.ashx?methodname=ipro.acc.properties.search.ajax.chosen";
  let params = {};

  params.limit = 10;
  params.term = words;

  const listItems = results.map((item, index) => {
    return (
      <div key={index.toString()}   className=' tw-relative   '>
        <a href={item.Id} target="_blank" rel="noreferrer" className='tw-p-1 tw-block' >
          {item.value}
        </a>
      </div>
    );
  }
  );
 
  useEffect(() => {
 
    if (words !== "" && words !== undefined) {
      axios.get(domain + APIUrl, {
        params: params,
      },
      ).then(function (response) {
        setResults(response.data);
        setDropDownVisability(true);
        setMessage('');
        if(response.data.length === 0) {
          setMessage('No results match');
        } 
      })
      .catch(function (error) {

      })
        .finally(function (response) {
        
       
      });
    }
  
    if(words === ''){
      setResults([]);
     // setMessage('No results match');
      setDropDownVisability(false);
    }


  }, [words])


  useEffect(() => { 
   
 
      if (results !== undefined) {
        //console.log(results.length, words);

        if (results.length === 0 && words.length > 0) {
          setResults([]);
         
        }
      }
 
  }, [words])

  function keyPressCallback(e) {
    params.term = e.target.value;
    setTerm(e.target.value);
  }

  const onClick = (e) => {
    setMessage('');
    setTerm('');

  };

  const unFocus = (e) => {

    setTimeout(() => {
      input.current.value = '';
      setTerm('');
      setMessage('');
      setDropDownVisability(false);
    }, 400)
  };
  return (
    <div className={styles.PropertyInputSearch + " tw-relative lg:tw-m-0 " + className } >
      <InputGroup  >
        
        <Form.Control
       
          placeholder="Enter property name"
          aria-label="Enter property name"
          aria-describedby="basic-addon"
          onKeyDown={debounce(keyPressCallback, 500)}
          onFocus={onClick}
          onBlur={unFocus}
          ref={input}
        />
        
        {/*<Button variant="outline-secondary" id="button-addon">
          <FontAwesomeIcon icon={faSearch} color="#183153" className="tw-right-5 tw-top-5  " />
        </Button>*/}
      </InputGroup>
      
      {dropDownVisability && 
        <div className={"tw-absolute tw-w-full  tw-bg-white tw-top-[40px] tw-p-2 rounded tw-shadow-md"} ref={dropdownRef} >
          {listItems}
          {message}
        </div>
      }
    </div>
  )
};


export default PropertyInputSearch;
