import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const navigate = useNavigate();
    const [properties, setProperties] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}ipro/webmethod.ashx?methodName=ipro.accommodation.owner.properties&p=1111&u=grahamneale@ipro-software.com`);
     
                const data = await response.json();
                
                // Check if the data is null or if there's an error in the response
                if (!data || data.error) {
                     navigate('/members/owner-login/');
                } else {
                    setProperties(data); // Assuming 'data' is the array of properties
                }
            } catch (error) {
                console.error("Failed to fetch properties:", error);
                 navigate('/members/owner-login/');
            }
        };

        fetchData();
    }, [navigate]);

    // Conditional rendering based on the fetched data
    if (properties === null) {
        return <div>Loading...</div>; // Or any other loading state representation
    }

    return (
        <div className="min-h-screen bg-gray-100">
            {/* Header and other UI elements */}
            <div>Dashboard Content Here</div>
            {/* You can map over properties and render them here */}
        </div>
    );
};

export default Dashboard;
