import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './SearchSorter.module.css';
import Form from 'react-bootstrap/Form';
const SearchSorter = ({ updateSorting }) => {

  useEffect(() => {
    let params = new URLSearchParams(document.location.search);
    let sort = params.get("Sort"); 
    if (sort !== ''){
      
    }
  },[])

  return (
    <div className={styles.SearchSorter}>
      <Form.Select onChange={updateSorting}>
          <option value="">Please Select</option>
          <option value="propertyMaxRate" sortdirection="asc">Price low to high</option>
          <option value="propertyMaxRate" sortdirection="desc">Price high to low</option>
          <option value="sleeps" sortdirection="asc">Sleeps: ascending</option>
          <option value="sleeps" sortdirection="descending">Sleeps: descending</option>
          <option value="bedrooms" sortdirection="asc">Bedrooms: ascending</option>
          <option value="bedrooms" sortdirection="descending">Bedrooms: descending</option>
          <option value="nodeName" sortdirection="asc">Property name</option>
          <option value="propertyReference" sortdirection="asc">Property reference</option>
          <option value="ourpick" sortdirection="asc">Our Pick</option>
      </Form.Select>
    </div>
  )
};

SearchSorter.propTypes = {};

SearchSorter.defaultProps = {};

export default SearchSorter;
