import React, { useState } from 'react';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';

// Define your SVG icons as string
const PlusSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>`;
const MinusSVG = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-minus"><line x1="5" y1="12" x2="19" y2="12"></line></svg>`;

const FAQWidget = ({ data }) => {
    const [openFAQIndexes, setOpenFAQIndexes] = useState([]);

    const handleTitleClick = (index) => {
        setOpenFAQIndexes(prevOpenIndexes =>
            prevOpenIndexes.includes(index)
                ? prevOpenIndexes.filter(i => i !== index)
                : [...prevOpenIndexes, index]
        );
    }

    return (
        <WidgetWrapper data={data}>
            <div className={'tw-shadow-lg '}>
                {data.FaqItems.map((faq, index) => (
                    <div className={'tw-p-5 tw-border-b cursor-pointer'}>
                        <div key={index} onClick={() => handleTitleClick(index)}>
                            <div className="tw-flex tw-justify-between tw-items-center " >
                                <h4 className="tw-m-0">{faq.Title}</h4>
                                <button >
                                    <span dangerouslySetInnerHTML={{ __html: openFAQIndexes.includes(index) ? MinusSVG : PlusSVG }} />
                                </button>
                            </div>
                            {openFAQIndexes.includes(index) && <div dangerouslySetInnerHTML={{ __html: faq.Content }} />}
                        </div>
                    </div>
                ))}
            </div>
        </WidgetWrapper>
    );
};

export default FAQWidget;