import React from 'react';
import PropTypes from 'prop-types';
import styles from './SkeletonSearchForm.module.css';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import useWindowSize from "utils/useWindowSize";
const SkeletonSearchForm = (props) => {
  const { width } = useWindowSize();
  if (width< window.lg){
    return (
      <div className={styles.SkeletonSearchForm + " lg:tw-flex"}>
        <Skeleton width={270} height={50} />
      </div>
    )
  }

  return(
    <div className={styles.SkeletonSearchForm + " lg:tw-flex"}>
      <Skeleton className="tw-mb-[15px] tw-mt-[15px] tw-mr-1 md:!tw-w-[120px]" height={50} />
      <Skeleton className="tw-mb-[15px] tw-mt-[15px] tw-mr-1 md:!tw-w-[120px]" height={50} />
      <Skeleton className="tw-mb-[15px] tw-mt-[15px] tw-mr-1 md:!tw-w-[120px]" height={50} />
      <Skeleton className="tw-mb-[15px] tw-mt-[15px] tw-mr-1 md:!tw-w-[120px]" height={50} />
      <Skeleton className="tw-mb-[15px] tw-mt-[15px] tw-mr-1 md:!tw-w-[120px]" height={50} />
      <Skeleton className="tw-mb-[15px] tw-mt-[15px] tw-mr-1 md:!tw-w-[120px]" height={50} />
    </div>
  );
};

SkeletonSearchForm.propTypes = {};

SkeletonSearchForm.defaultProps = {};

export default SkeletonSearchForm;
