import React, { useEffect, useState, useRef } from 'react';

 
export const useReadCookie = (name) => {
    const isFirstRun = useRef(true);
    const [currentCookie, setCurrentCookie] = useState(name);
 
    useEffect(() => {

        if (!isFirstRun.current){
            isFirstRun.current = false;
        }else{
            var nameEQ = currentCookie + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) === ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) === 0) setCurrentCookie(c.substring(nameEQ.length, c.length));
            }
        }
       
        
    }, [currentCookie])

    return [currentCookie];
};


export default useReadCookie;
