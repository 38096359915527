import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import useWindowSize from "utils/useWindowSize";

const DropdownIcon = ({ width, isPlus }) => (
  <FontAwesomeIcon
    size={width < window.md ? "2x" : "sm"}
    icon={isPlus ? faPlus : faAngleDown}
    color="primary"
  />
);

const ChildMenu = ({ children, width, handleMenuClick, navigationDepth }) => (
  navigationDepth > 2 && children.map((child, ind) => (
    <li key={ind} className="level3-menu-item hvr-underline-from-left">
      <a href={child.Url} className="level3-menu-link lg:tw-p-2 tw-text-lg md:tw-text-base" onClick={handleMenuClick}>
        {child.Name}
      </a>
    </li>
  ))
);

const SubMenu = ({ el, width, handleMenuClick, runFunction, itemEls, navigationDepth }) => (
  navigationDepth > 1 && el.Children?.map((child, subIndex) => (
    <li key={subIndex + "" + child.Id} className="level2-item-menu hvr-underline-from-left tw-relative">
      <Link to={child.Url} className="level2-menu-link lg:tw-p-2 tw-text-lg md:tw-text-base" onClick={handleMenuClick}>
        {child.Name}
      </Link>
      {navigationDepth > 2 && child.Children?.length > 0 &&
        <span className="tw-ml-2 tw-mt-1 tw-absolute tw-right-[10px]" onClick={() => runFunction(itemEls.current[child.Id])} ref={(element) => itemEls.current[child.Id] = element}>
          <DropdownIcon width={width} isPlus={width < window.md} />
        </span>}
      {child.Children.length > 0 &&
        <ul className="tw-shadow-md lg:tw-p-0 tw-h-0 menu-drop-down-level-3 tw-overflow-hidden level-3">
          <ChildMenu children={child.Children} width={width} handleMenuClick={handleMenuClick} navigationDepth={navigationDepth} />
        </ul>}
    </li>
  ))
);


export const NavigationMenu = ({ menuNodes, open, closeMenu, navigationDepth }) => {
  const itemEls = useRef([]);
  const location = useLocation();
  const { width } = useWindowSize();
  const [showDropdown, setShowDropdown] = useState(true);

  const runFunction = ref => {
    let dropDown = ref.nextElementSibling;
    dropDown.classList.toggle('tw-h-0');
  }

  const handleMenuClick = e => {
    setShowDropdown(false);
    closeMenu(true);
  }

  useEffect(() => {
    setShowDropdown(true);
  }, [location]);

  return (
    <>
      {(open || width > 1023) &&
        <div className="NavigationMenu ">
          <ul className="tw-block tw-p-0 tw-m-0 lg:tw-flex lg:tw-justify-between">
            {menuNodes && menuNodes.map((element, index) => (
              <li key={index + "" + element.Id} className="hvr-underline-from-left tw-block lg:tw-inline tw-relative tw-py-2 xl:tw-p-2 tw-border-b lg:tw-border-none level1-item-menu tw-uppercase">
                <Link to={element.Url} className="level1-link ">
                  {element.Name}
                </Link>
                {navigationDepth > 1 && element.Children?.length > 0 &&
                  <span className=" tw-absolute tw-right-0 lg:tw-relative lg:tw-top-[2px] tw-pl-2" onClick={() => runFunction(itemEls.current[index])} ref={(element) => itemEls.current[index] = element}>
                    <DropdownIcon width={width} />
                  </span>}
                {(element.Children?.length > 0 && showDropdown) &&
                  <ul className='tw-bg-white lg:tw-p-0 lg:tw-shadow-md menu-drop-down menu-drop-down-level-1 lg:tw-absolute tw-pl-2 tw-h-0 tw-overflow-hidden tw-ease-in tw-duration-100'>
                    <SubMenu el={element} width={width} handleMenuClick={handleMenuClick} runFunction={runFunction} itemEls={itemEls} navigationDepth={navigationDepth} />
                  </ul>}
              </li>
            ))}
             <li className="hvr-underline-from-left tw-block lg:tw-inline tw-relative tw-py-2 xl:tw-p-2 tw-border-b lg:tw-border-none level1-item-menu tw-uppercase">
              <a href="https://hosts.aplacelikehome.co.uk/" target="_blank" className="level1-link ">
                Host Your Home
              </a>   
            </li>
             
          </ul>
        </div>}
    </>
  );
};