import React from 'react';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';
import PropertyItem from 'components/SearchResult/PropertyItem/PropertyItem';
import Slider from 'react-slick';  // Import Slider from react-slick
import 'slick-carousel/slick/slick.css';  // Default styling
import 'slick-carousel/slick/slick-theme.css';  // Default theme
import styles from './PropertyPickerWidgetCarousel.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';


const PropertyPickerWidgetCarousel = ({ data }) => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    nextArrow: null,
                    prevArrow: null
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    nextArrow: null,
                    prevArrow: null
                }
            }
        ]
    };



    return (
        <WidgetWrapper data={data}>
            {data.Title && <h2>{data.Title}</h2>}
            {data.SubTitle && <h3>{data.SubTitle}</h3>}

            <div className={`${styles.PropertyPickerWidgetCarouselWrapper}`}>
                <Slider {...settings}>
                    {data.PropertiesList.map((property, index) => (
                        <div key={index}>
                            <PropertyItem 
                                item={property} 
                                index={index} 
                                enableSlider={true} 
                                className={`tw-my-5 tw-mx-4`}
                                dictionary={'night'}
                            />
                        </div>
                    ))}
                </Slider>
            </div>
        </WidgetWrapper>
    )
};

// Custom Arrow components
function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} ${styles.customArrow} ${styles.nextArrow}`}
            style={{ ...style, display: "block" }}  // Custom styling if needed
            onClick={onClick}
        >
            <FontAwesomeIcon 
                icon={faChevronRight} 
                style={{
                    color: "#ccc",
                    fontSize: "50px"
                }}
            />
        </div>
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} ${styles.customArrow} ${styles.prevArrow}`}
            style={{ ...style, display: "block" }}  // Custom styling if needed
            onClick={onClick}
        >
            <FontAwesomeIcon 
                icon={faChevronLeft} 
                style={{
                    color: "#ccc",
                    fontSize: "50px"
                }}
            />
        </div>
    );
}

export default PropertyPickerWidgetCarousel;