import React from 'react';
import PropTypes from 'prop-types';
import styles from './PropertyPoint.module.css';

const PropertyPoint = (props) => {
  
  return (
    <div className={styles.PropertyPoint}>
      {props.Price}
    </div>
  );
};

PropertyPoint.propTypes = {};

PropertyPoint.defaultProps = {};

export default PropertyPoint;
