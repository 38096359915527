// ClientPortal.js
import React from 'react';
import Sidebar from './Sidebar';
import Dashboard from './Dashboard/Dashboard';
import Profile from './Profile/Profile';

// Map template strings to component functions
const TEMPLATE_COMPONENTS = {
    TBS_AccommodationHome: Dashboard,
    profile: Profile,
};

function ClientPortal({ template }) {
    
    const TemplateComponent = TEMPLATE_COMPONENTS[template] || (() => <div>Template not found</div>);
   
    return (
        <>
            <div className="tw-flex h-screen">
                <Sidebar />
                <div className="tw-flex-1">
                    <TemplateComponent />
                </div>
            </div>
        </>
    );
}

export default ClientPortal;