import React, { useState, useEffect, useRef, useContext } from 'react';
import styles from './PriceRange.module.css';
import RangeSlider from 'react-range-slider-input';
import './style.scss';
import useGetUrlParams from 'utils/useGetUrlParams';
import { CurrencyContext } from 'contexts/SiteSettingsProvider';

function debounce(cb, interval, immediate) {
  var timeout;
  return function () {
    var context = this, args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) cb.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, interval);

    if (callNow) cb.apply(context, args);
  };
};

const PriceRange = ({ changeNightRange, changeWeekRange, changePriceMode, priceRangeSliderSettings, resetPrice, OpenedModal }) => {

  

  const refNightlySwitch = useRef(null);
  const refWeeklySwitch = useRef(null);

  const minNightInput = useRef(null);
  const maxNightInput = useRef(null);
  const minWeekInput = useRef(null);
  const maxWeekInput = useRef(null);

  

  let [showNightlyPriceSlider, setShowNightlyPriceSlider] = useState(true);
  let [showWeeklyPriceSlider, setShowWeeklyPriceSlider] = useState(true);


  let [currentMinNightParam, setCurrentMinNightParam] = useState(0);
  let [currentMaxNightParam, setCurrentMaxNightParam] = useState(0);
  let [currentMinWeekParam, setCurrentMinWeekParam] = useState(0);
  let [currentMaxWeekParam, setCurrentMaxWeekParam] = useState(0);

 
 
  const [currency, setCurrency] = useContext(CurrencyContext);
  const [rangeCurrency, setRangeCurrency] = useState('');

  let [period, setPeriod] = useState('Nightly');
  let [minNight, setMinNight] = useState(0);
  let [maxNight, setMaxNight] = useState(10000);
  let [minWeekly, setMinWeekly] = useState(0);
  let [maxWeekly, setMaxWeekly] = useState(10000);
 
  let [nightlyParamsRange] = useGetUrlParams('NightlyPriceRange');
  let [weeklyParamsRange] = useGetUrlParams('WeeklyPriceRange');
  let [priceFilterType] = useGetUrlParams('PriceFilterType');
 
  let [currentMinNight, setCurrentMinNight] = useState(currentMinNightParam);
  let [currentMaxNight, setCurrentMaxNight] = useState(currentMaxNightParam);

  let [currentMinWeekly, setCurrentMinWeekly] = useState(currentMinWeekParam);
  let [currentMaxWeekly, setCurrentMaxWeekly] = useState(currentMaxWeekParam);

  let [currentMode, setCurrentMode] = useState('Nightly');

 
  useEffect(() => {
    setCurrentMinNight(minNight);
    setCurrentMaxNight(maxNight);
    setCurrentMinWeekly(minWeekly);
    setCurrentMaxWeekly(maxWeekly);
  }, [resetPrice]);

  useEffect(() => {
 
    if (nightlyParamsRange !== null ){
      if (nightlyParamsRange !== ''){
        let arr = nightlyParamsRange.split(',');
        setCurrentMinNight(parseInt(arr[0]));
        setCurrentMaxNight(parseInt(arr[1]));
        if (parseInt(arr[1]) === 0 ){
          setCurrentMaxNight(maxNight);
        }
      }
      if (nightlyParamsRange === '') {
        setCurrentMinNight(minNight);
        setCurrentMaxNight(maxNight);
        changeNightRange('');
      }
    }
   
    if (weeklyParamsRange !== null) {
      if (weeklyParamsRange !== '') {
        let arr = weeklyParamsRange.split(',');
        setCurrentMinWeekly(parseInt(arr[0]));
        setCurrentMaxWeekly(parseInt(arr[1]));
        if (parseInt(arr[1]) === 0) {
          setCurrentMaxWeekly(maxWeekly);
        }
      }
      if (weeklyParamsRange === '') {
        changeWeekRange('');
        setCurrentMinWeekly(minWeekly);
        setCurrentMaxWeekly(maxWeekly);
      }
    }
     
  }, [nightlyParamsRange, weeklyParamsRange]);

  useEffect(() => {
 
    if (priceFilterType !== null ) {
      if (priceFilterType === 'Weekly' || priceFilterType === 'weekly' ) {
        if (refWeeklySwitch.current){
          refWeeklySwitch.current.checked = true;
          setCurrentMode("Weekly");
        }
      }
      if (priceFilterType === 'Nightly' || priceFilterType === 'nightly') {
        if (refNightlySwitch.current){
          refNightlySwitch.current.checked = true;
          setCurrentMode("Nightly");
        }
      }
      if (priceFilterType === '') {
        changePriceMode('Weekly');
      }
    }

  }, [priceFilterType, refWeeklySwitch]);

 
  useEffect(() => {
    setMinNight(priceRangeSliderSettings.MinNightlyPrice);
    setMaxNight(priceRangeSliderSettings.MaxNightlyPrice);
    setMinWeekly(priceRangeSliderSettings.MinWeeklyPrice);
    setMaxWeekly(priceRangeSliderSettings.MaxWeeklyPrice);
  }, []);

  useEffect(() => {

    setShowNightlyPriceSlider(priceRangeSliderSettings.NightlySliderEnabled);
    setShowWeeklyPriceSlider(priceRangeSliderSettings.WeeklySliderEnabled);
 

    if (priceRangeSliderSettings.NightlySliderEnabled === false || priceRangeSliderSettings.NightlySliderEnabled === null) {
      setCurrentMode('Weekly');
      refWeeklySwitch.current.checked = true;
    }

    if (priceRangeSliderSettings.WeeklySliderEnabled === false || priceRangeSliderSettings.WeeklySliderEnabled === null){
      setCurrentMode('Nightly');
      refNightlySwitch.current.checked = true;
    }  
     
  }, [currency, period, minNight, maxNight, currentMinNight, currentMaxNight, currentMode, priceRangeSliderSettings]);

 

  useEffect(() => {
    let response = window.sessionStorage.getItem("sitesettings");
    response = JSON.parse(response);

    if (currency !==''){
      setRangeCurrency(currency);
    }
    if (currency || currency === ""){
      console.log(currency);
      if (response.Currency && response.Currency !== ""){
        setRangeCurrency(currency || response.Currency);
      }
    }
  }, [currency]);

  useEffect(() => {
  
    if (minNightInput.current !== null && maxNightInput.current !== null) {

      minNightInput.current.value = priceRangeSliderSettings.MinNightlyPrice;
      maxNightInput.current.value = priceRangeSliderSettings.MaxNightlyPrice;
      if (currentMinNight !== 0 && currentMaxNight !== 0 ) {
        minNightInput.current.value = currentMinNight;
        maxNightInput.current.value = currentMaxNight;
      }
    }
  }, [currentMode, OpenedModal, currentMinNight, currentMaxNight]);

  useEffect(() => {
    if (minWeekInput.current !== null && maxWeekInput.current !== null) {
      minWeekInput.current.value = priceRangeSliderSettings.MinWeeklyPrice;
      maxWeekInput.current.value = priceRangeSliderSettings.MaxWeeklyPrice;
      if (currentMinWeekly !== 0 && currentMaxWeekly !== 0) {
        minWeekInput.current.value = currentMinWeekly;
        maxWeekInput.current.value = currentMaxWeekly;
      }
    }
  }, [currentMode, OpenedModal, currentMinWeekly, currentMaxWeekly]);
  
 

  const changeMinNight = (e) => {
 
    const val = parseInt(e.target.value);
    minNightInput.current.value = val;
    setCurrentMinNight(val);
    if (val < minNight || val > maxNight || val === null || isNaN(val)) {
      minNightInput.current.value = minNight
      setCurrentMinNight(minNight);
    }
  }
 
  const changeMaxNight = (e) => {
      const val = parseInt(e.target.value);
      maxNightInput.current.value = val;
      setCurrentMaxNight(val);
      if (val > maxNight || val < minNight || val === null || isNaN(val)) {
        setCurrentMaxNight(maxNight)
        maxNightInput.current.value = maxNight;
      }
  }

  const changeMinWeek= (e) => {
    const val = parseInt(e.target.value);
    setCurrentMinWeekly(val);
    minWeekInput.current.value = val;
    if (val < minWeekly || val > maxWeekly || val === null || isNaN(val)) {
      setCurrentMinWeekly(minWeekly);
      minWeekInput.current.value = minWeekly;
    }
  }

  const changeMaxWeek = (e) => {
    const val = parseInt(e.target.value);
    setCurrentMaxWeekly(val);
    maxWeekInput.current.value = val;
    if (val > maxWeekly || val < minWeekly || val === null || isNaN(val)) {
      setCurrentMaxWeekly(maxWeekly)
      maxWeekInput.current.value = maxWeekly;
    }
  }

  const getValuesNightly = (e) => {
    changeNightRange([e[0], e[1]].toString());
    setCurrentMinNight(e[0]);
    setCurrentMaxNight(e[1]);
    if (minNightInput.current !== null && maxNightInput.current !== null) {
      minNightInput.current.value = e[0];
      maxNightInput.current.value = e[1];
    }
  };

  const getValuesWeekly = (e) => {
     
    changeWeekRange([e[0], e[1]].toString());
    setCurrentMinWeekly(e[0]);
    setCurrentMaxWeekly(e[1]);
    if (minWeekInput.current !== null && maxWeekInput.current !== null) {
      minWeekInput.current.value =  e[0];
      maxWeekInput.current.value =  e[1];
    }
  };

  const changeToNightly = (e) => {
    setCurrentMode('Nightly');
    changePriceMode('Nightly');
    setCurrentMinNight(minNight);
    setCurrentMaxNight(maxNight);

  }

  const changeToWeekly = (e) => {
    setCurrentMode('Weekly');
    changePriceMode('Weekly');
    setCurrentMinWeekly(minWeekly);
    setCurrentMaxWeekly(maxWeekly);
  }
 
  function changeToWeek(){
    setCurrentMinNight(minNight);
    setCurrentMaxNight(maxNight);
    setCurrentMode('Weekly');
    changePriceMode('Weekly');
    changeWeekRange([currentMinWeekly, currentMaxWeekly].toString());
    changeNightRange('')
  }

  function changeToNight() {
    
    setCurrentMode('Nightly');
    changePriceMode('Nightly');
    setCurrentMinWeekly(minWeekly);
    setCurrentMaxWeekly(maxWeekly);
    changeNightRange([currentMinNight, currentMaxNight].toString());
    changeWeekRange('');
  }

  return (
    <div className="tw-mb-5">
      <h5 className="tw-mb-5">Price Range  </h5>
      <div className="tw-mb-5">
        {showNightlyPriceSlider && 
         <>
          <input type="radio" ref={refNightlySwitch} onChange={changeToNightly} onClick={changeToNight}  className="btn-check" name="options-outlined" id="nightly-price" defaultChecked autoComplete="off" />
          <label className="btn btn-outline-primary btn-sm tw-mr-2" htmlFor="nightly-price">Nightly</label>
         </>
        }
        {showWeeklyPriceSlider && 
          <>
          <input type="radio" ref={refWeeklySwitch} onChange={changeToWeekly} onClick={changeToWeek} className="btn-check " name="options-outlined" id="weekly-price" autoComplete="off" />
          <label className="btn btn-outline-primary btn-sm" htmlFor="weekly-price">Weekly</label>
          </>
        }
      </div>
      {(currentMode === 'Nightly' && showNightlyPriceSlider)  ? <>
        <RangeSlider className='night-range-slider'  min={minNight} max={maxNight} value={[currentMinNight, currentMaxNight]} defaultValue={[0, 20000]} onInput={getValuesNightly}  />
        <div className="tw-flex tw-justify-between tw-items-center tw-mt-5">
          <div className="input-group">
            <div className="input-group-text">{rangeCurrency}</div>
            <input type="text" className="form-control" placeholder="Min Price" ref={minNightInput} onChange={debounce(changeMinNight, 700)} />
          </div>
          <span className="tw-flex tw-min-w-[30px] tw-justify-center">–</span>
          <div className="input-group">
            <div className="input-group-text">{rangeCurrency}</div>
            <input type="text" className="form-control" placeholder="Max Price" ref={maxNightInput} onChange={debounce(changeMaxNight, 700)} />
          </div>
        </div> 
      </> : 
      <>
          {currentMode === 'Weekly' && showWeeklyPriceSlider ? 
          <>
              <RangeSlider className='weekly-range-slider' min={minWeekly} max={maxWeekly} value={[currentMinWeekly, currentMaxWeekly]} defaultValue={[0, 20000]} onInput={getValuesWeekly}  />
              <div className="tw-flex tw-justify-between tw-items-center tw-mt-5">
                <div className="input-group">
                  <div className="input-group-text">{rangeCurrency}</div>
                  <input type="text" className="form-control" placeholder="Min Price" ref={minWeekInput} onChange={debounce(changeMinWeek, 700)} />
                </div>
                <span className="tw-flex tw-min-w-[30px] tw-justify-center">–</span>
                <div className="input-group">
                  <div className="input-group-text">{rangeCurrency}</div>
                  <input type="text" className="form-control" placeholder="Max Price" ref={maxWeekInput} onChange={debounce(changeMaxWeek, 700)} />
                </div>
              </div> 
          </>: null 
        }
      </> 
      }
    </div>
  );
};
 

export default PriceRange;
