import React, { useState } from 'react';
import moment from 'moment';
import { FaStar, FaRegStar } from 'react-icons/fa';
import './Reviews.css'; // Assuming you have a CSS file for styling.
import Skeleton from 'react-loading-skeleton';

const Reviews = ({ reviews, isLoading }) => {
    const [showAll, setShowAll] = useState(false);

    if (!reviews) {
        return (
            <div className="reviews-container">
                <h4>Reviews</h4>
                <p>No reviews</p>
            </div>
        );
    }

    const sortedReviews = [...reviews].sort((a, b) => moment(b.PublishedDate).valueOf() - moment(a.PublishedDate).valueOf());

    const renderStars = (rating) => {
        let stars = [];
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
                stars.push(<FaStar key={i} color="#FFD700" />);
            } else {
                stars.push(<FaRegStar key={i} />);
            }
        }
        return stars;
    };

    if (isLoading) {
        return (
            <div className="reviews-container">
                <h4>Reviews</h4>
                <Skeleton count={10} height={50} />
            </div>
        );
    }

    const reviewsToShow = showAll ? sortedReviews : sortedReviews.slice(0, 5);

    return (
        <div className="reviews-container">
            <h4>Reviews ({sortedReviews.length})</h4>
            {reviewsToShow.map((review, index) => (
                <div key={index} className="review-card">
                    <h5>{review.Title}</h5>
                    <p dangerouslySetInnerHTML={{ __html: review.Description }}></p>
                    <div className="review-details">
                        <p>{review.Reviewer}</p>
                        <div><b>Rating:</b> {renderStars(review.Rating)}</div>
                        <p>{moment(review.PublishedDate).format('Do MMMM YYYY')}</p>
                        {review.ManagementComments && (
                            <div className="management-reply">
                                <p><b>Reply:</b> {review.ManagementComments}</p>
                            </div>
                        )}
                    </div>
                </div>
            ))}
            {sortedReviews.length > 5 && (
                <button onClick={() => setShowAll(!showAll)}>
                    Show {showAll ? "less" : "more"}
                </button>
            )}
        </div>
    );
};

export default Reviews;