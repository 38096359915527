import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faPersonWalkingLuggage } from '@fortawesome/pro-light-svg-icons';

const Map = ({ geoLocation, GoogleMapAPIKey, isLoading, distances, locationDescription }) => {
    if (!geoLocation || geoLocation.length === 0) {
        return
    }

    const [lat, lng, zoom='5'] = geoLocation.split(',').map(Number);

    if (isLoading) {
        return (
            <div>
                <h4>Map</h4>
                <Skeleton height={400} />
            </div>
        );
    }

    const mapStyles = [
        {
            featureType: "all",
            elementType: "all",
            stylers: [{ saturation: -100 }, { gamma: 0.5 }],
      
        }
    ];

    return (
        <>
            <LoadScript googleMapsApiKey={GoogleMapAPIKey}>
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '400px' }}
                    center={{ lat, lng }}
                    zoom={zoom}
                    options={{
                       // styles: mapStyles, // Your grayscale style array
                    }}
        
                >
                    <Marker 
                        position={{ lat, lng }}
                        icon={{
                            url: '/clientImages/marker.png',
                            //scaledSize: new window.google.maps.Size(30, 30),
                        }}
                     />
                </GoogleMap>
            </LoadScript>
            {distances && distances.length > 0 && (
                <div className="tw-mt-6  ">
                    <h4 className="tw-text-lg tw-font-semibold tw-text-gray-700 tw-mb-4">Distances</h4>
                    <ul className="tw-p-0 tw-text-lg">
                        {distances.map(item => {
                            let modeOfTransport, icon;
                            if (item.ByCar) {
                                modeOfTransport = `drive`;
                                icon = <FontAwesomeIcon icon={faCar} className="tw-text-blue-500 tw-mr-2" />;
                            } else if (item.ByFoot) {
                                modeOfTransport = ``;
                                icon = <FontAwesomeIcon icon={faPersonWalkingLuggage} className="tw-text-green-500 tw-mr-2" />;
                            }

                            return (
                                <li key={item.Id} className="tw-text-gray-600 tw-text-md tw-font-medium">
                                    {icon} {item.Name} is {item.Distance} {item.DistanceUnit.toLowerCase()} {modeOfTransport} 
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
            {(locationDescription && locationDescription.length > 0) &&
                <div className="tw-mt-6">
                   <h6>Location Description</h6>
                    <p>
                        {locationDescription}
                    </p>
                </div>
            }
        </>
    );
};

export default Map;