import { useEffect, useState, useContext } from 'react';
import SearchForm from '../../components/SearchForm/SearchForm';
import { SiteSettingsContext, CurrencyContext } from '../../contexts/SiteSettingsProvider';
import useWindowSize from "utils/useWindowSize";
import PropertiesList from 'pages/ContentPage/Properties/PropertiesList.js';
import Cookies from 'js-cookie';
import axios from 'axios';
import SkeletonProperty from 'components/SkeletonProperty/SkeletonProperty';

const WishlistPage = ({ pageId, keyID }) => {

    const siteSettings = useContext(SiteSettingsContext);
    const { width } = useWindowSize();
    const [currency, setCurrency] = useContext(CurrencyContext);
    const [propertyList, setPropertyList] = useState(null);
    const [isFetching, setIsFetching] = useState(true);
    let domain = process.env.REACT_APP_BASE_URL;
    let APIUrl = "apis/searchproperties";
    useEffect(() => {
        const wishlist = Cookies.get('ipro-wishlist');
        if (wishlist) {
            const ids = wishlist.split(",");
            axios.get(domain + APIUrl, {
                params: {
                    clearcache: true,
                    PropertyIds: ids
                },
            },
            ).then(response => {
                console.log(response.data);
                setPropertyList(response.data.Items);
                setIsFetching(false);
            })
                .catch(error => {
                    console.error("Error fetching properties", error);
                });
        }
    }, []); // adding empty dependency array to run once on mount

    return (
        <div className="tw-mt-5">
            <div className="tw-container tw-m-auto">
                {isFetching ?
                    <div className="tw-grid sm:tw-grid-cols-1 md:tw-grid-cols-4 lg:tw-grid-cols-5 tw-gap-4 tw-gap-y-6">
                        <SkeletonProperty count={20} />
                    </div> : < PropertiesList propertyList={propertyList} />}

            </div>
        </div>
    );
};

export default WishlistPage;