import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Header.css';
import Skeleton from "react-loading-skeleton";
import SocialNetworks from 'components/SocialNetworks/SocialNetworks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faMobileRetro } from "@fortawesome/free-solid-svg-icons"

const Header = (headerSettings) => {

  
  let [isLoading, setIsLoading] = useState(true);
  let [enableHeader, setEnableHeader] = useState();
  let [contactEmail, setContactEmail] = useState();
  let [contactMobilePhone, setContactMobilePhone] = useState();
  let [contactTelephone, setContactTelephone] = useState();
  let [desktopLogo, setDesktopLogo] = useState();
  let [desktopLogoHeight, setDesktopLogoHeight] = useState();
  let [desktopLogoWidth, setDesktopLogoWidth] = useState();
  let [enableSocialMediaHeader, setEnableSocialMediaHeader] = useState();
  let [mobileLogo, setMobileLogo] = useState();
  let [mobileLogoHeight, setMobileLogoHeight] = useState();
  let [mobileLogoWidth, setMobileLogoWidth] = useState();

  let [showAddressInformation, setShowAddressInformation] = useState();
  let [showTheSiteName, setShowTheSiteName] = useState();
  let [showTheSlogan, setShowTheSlogan] = useState();
  let [theSlogan, setSiteSlogan] = useState();
  let [siteName, setSiteName] = useState();
  
  let [headerColorClass, setHeaderColorClass] = useState('');
 
  
   
  useEffect(() => {

    if (headerSettings.siteSettings){
 
      setContactEmail(headerSettings.siteSettings.ContactEmail);
      setContactMobilePhone(headerSettings.siteSettings.ContactMobilePhone);
      setContactTelephone(headerSettings.siteSettings.ContactTelephone);
      setDesktopLogo(headerSettings.siteSettings.DesktopLogo);
      setEnableSocialMediaHeader(headerSettings.headerSettings.EnableSocialMediaHeader);
      setMobileLogo(headerSettings.siteSettings.MobileLogo);
      setShowAddressInformation(headerSettings.headerSettings.ShowAddressInformation);
      setShowTheSiteName(headerSettings.headerSettings.ShowTheSiteName);
      setShowTheSlogan(headerSettings.headerSettings.ShowTheSlogan);
      setSiteName(headerSettings.siteSettings.SiteName);
      setSiteSlogan(headerSettings.siteSettings.SiteSlogan);
      setDesktopLogoHeight(headerSettings.siteSettings.DesktopLogoHeight);
      setDesktopLogoWidth(headerSettings.siteSettings.DesktopLogoWidth);
      setMobileLogoHeight(headerSettings.siteSettings.MobileLogoHeight);
      setMobileLogoWidth(headerSettings.siteSettings.MobileLogoWidth);
    }
    
  


  }, [headerSettings]);

  useEffect(() => {
    let breadcrumbs = document.getElementsByTagName('body')[0].getAttribute('headers');
    if (breadcrumbs === "1") {
      setEnableHeader(true);
      return;
    }
    setEnableHeader(false);
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("sitesettings") === null) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1100);
    } else {
      setIsLoading(false);
    }

  }, []);

  if (!enableHeader){
    return;
  }

  return (

    <>
      {
        !isLoading ?
          <div className={" header-wrapper " + headerColorClass}>
            <div className="container-fluid">

              {enableHeader &&
                <div className="tw-flex tw-flex-col sm:tw-grid sm:tw-grid-cols-[1fr,2fr] tw-place-content-between tw-auto-cols-auto tw-items-center tw-py-3">

                  <div className="header-logo-wrapper lg:tw-flex tw-items-center tw-text-center">
                    <div className="header-logo lg:tw-inline">
                      {desktopLogo !== null ?
                        <a href="/">
                          <img src={desktopLogo} className="img-responsive tw-hidden md:tw-inline desktop-logo" width={desktopLogoWidth} height={desktopLogoHeight} />
                        </a>
                        : null}
                      {mobileLogo !== null ?
                        <a href="/">
                          <img src={mobileLogo} className="img-responsive md:tw-hidden mobile-logo" width={mobileLogoWidth} height={mobileLogoHeight}/>
                        </a>
                        : null
                      }  
                   </div>

                    <div className='name-slogan'>
                      <div className="site-name">
                        {showTheSiteName  ? siteName : null}
                      </div>

                      <div className="site-slogan">
                        {showTheSlogan ? theSlogan : null}
                      </div>
                    </div>
                  </div>

                  <div className="header-info  xl:tw-flex sm:tw-justify-end tw-gap-3">
                    {showAddressInformation ?
                      <ul className="header-contact-info tw-p-0 tw-m-0  tw-flex tw-gap-3 tw-text-center sm:tw-text-right md:tw-justify-end">
                        {contactEmail && <li className="site-phone xl:tw-inline">
                          <a href={'tel:' + contactEmail.toString()} className="tw-no-underline">
                            <FontAwesomeIcon size="lg" icon={faEnvelope} color="primary" className="tw-mr-2" />
                            <span className="site-phone-text tw-hidden lg:tw-inline-block">{contactEmail}</span>
                          </a>
                        </li>}
                        {contactMobilePhone && <li className="site-phone-mob xl:tw-inline">
                          <a href={'tel:' + contactMobilePhone.toString()} className="tw-no-underline">
                            <FontAwesomeIcon size="lg" icon={faMobileRetro} color="primary" className="tw-mr-2" />
                            <span className="site-phone-mob-text tw-hidden lg:tw-inline-block">{contactMobilePhone}</span>
                          </a>
                        </li>}
                        {contactTelephone && <li className="site-phone xl:tw-inline">
                          <a href={'tel:' + contactTelephone.toString()} className="tw-no-underline">
                            <FontAwesomeIcon size="lg" icon={faPhone} color="primary" className="tw-mr-2" />
                            <span className="site-phone-text tw-hidden lg:tw-inline-block">{contactTelephone}</span>
                          </a>
                        </li>}

                      </ul>
                      : null}

                    {enableSocialMediaHeader && <SocialNetworks size={'xl'} wrapperclassName="tw-flex tw-gap-3 tw-justify-center sm:tw-justify-end xl:tw-justify-start" className={"icons-social"} color={'primary'} socialNetworks={headerSettings.socialNetworks} />}

                  </div>
                </div>
              }
            </div>
          </div>
          :
          
          <div className={" tw-py-3 tw-bg-[#eee]"}>
            < div className="container-fluid " >
              <Skeleton height={48} />
            </div >
          </div >
      }

    </>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
