import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Navigation.scss';
import Skeleton from "react-loading-skeleton";
import { Outlet, Link } from "react-router-dom";
import { LanguageSwitcher } from 'components/LanguageSwitcher/LanguageSwitcher';
import { CurrencySelector } from 'components/CurrencySelector/CurrencySelector';
import { PropertyInputSearch } from 'components/PropertyInputSearch/PropertyInputSearch';
import { NavigationMenu } from 'components/NavigationMenu/NavigationMenu';
import { NavigationMegaMenu } from 'components/NavigationMegaMenu/NavigationMegaMenu';
import GoogleTranslateSwitcher from 'components/GoogleTranslateSwitcher/GoogleTranslateSwitcher';
import { BrowserRouter } from 'react-router-dom';
import useWindowSize from "utils/useWindowSize";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleBookmark, faPhone } from "@fortawesome/pro-light-svg-icons";
import { useLocation } from 'react-router-dom';
import { set } from 'date-fns';

const Navigation = (settings) => {
  const { width } = useWindowSize();
  const { height } = useWindowSize();
 
  let [isLoading, setIsLoading] = useState(true);

  let [enablenavigation, setEnablenavigation] = useState('');
  let [enableDropdown, setEnableDropdown] = useState();
  let [navigationDepth, setNavigationDepth] = useState();
  let [showHome, setShowHome] = useState();
  let [centerNav, setCenterNav] = useState();
 
  let [megaMenu, setMegaMenu] = useState();
  let [enableNavigationLogo, setEnableNavigationLogo] = useState();
  let [showWishlist, setShowWishlist] = useState();
  let [showWishlistUrl, setShowWishlistUrl] = useState();
  let [showWishlistName, setShowWishlistName] = useState();
  let [showguestLogin, setShowGuestLogin] = useState();
  let [guestLoginUrlName, setGuestLoginUrlName] = useState();
  let [guestLoginUrl, setGuestLoginUrl] = useState();
 
  let [showPhoneNumberNavigation, setShowPhoneNumberNavigation] = useState();

  let [menuNodes, setMenuNodes] = useState([]);
  
  let [showCurrencySelector, setShowCurrencySelector] = useState();
  let [showLanguageSwitcher, setShowLanguageSwitcher] = useState();
  let [showShowGoogleTranslate, setShowGoogleTranslate] = useState(null);
  let [showEnableSearch, setEnableSearch] = useState();

  let [callUsDictionary, setCallUsDictionary] = useState();

  let [currency, setCurrency] = useState();
  let [currecncies, setCurrencies] = useState();
  let [contactTelephone, setContactTelephone] = useState();
  let [desktopLogo, setDesktopLogo] = useState();
  let [desktopLogoHeight, setDesktopLogoHeight] = useState();
  let [desktopLogoWidth, setDesktopLogoWidth] = useState();
  let [mobileLogo, setMobileLogo] = useState();
  let [mobileLogoHeight, setMobileLogoHeight] = useState();
  let [mobileLogoWidth, setMobileLogoWidth] = useState();

  let [headerColorClass, setHeaderColorClass] = useState('');

  let [languages, setLanguages] = useState('');
  let [language, setLanguage] = useState('');
  let [navOpen, setNavOpen] = useState(false);
  const [home, setHome] = useState('');

  const location = useLocation();
 

  useEffect(() => {
    if (location.pathname === '/') {
      setHome('homepage');
    }  
    else {
      setHome('');
    }
  }, [location]);


  useEffect(() => {
 
    if (settings.navigationSettings.CenterNavigationBar){
      setCenterNav('center');
    }else{
      setCenterNav('between');
    }
 
    setContactTelephone(settings.siteSettings.ContactTelephone);
    setCallUsDictionary(settings.navigationSettings.CallUsDictionary);
     
    setEnableDropdown(settings.navigationSettings.EnableDropdowns);
    setMegaMenu(settings.navigationSettings.EnableMegaMenu);
    setEnableNavigationLogo(settings.navigationSettings.EnableNavigationLogo);
    setEnableSearch(settings.navigationSettings.Enablesearch);
    setShowGuestLogin(settings.navigationSettings.GuestLoginNavigation);
    setGuestLoginUrlName(settings.navigationSettings.GuestLoginUrlName);
    setGuestLoginUrl(settings.navigationSettings.GurestLoginUrl);
    setMenuNodes(settings.navigationSettings.MenuNodes);
    
    setNavigationDepth(settings.navigationSettings.NavigationDepth);
    setShowPhoneNumberNavigation(settings.navigationSettings.PhoneNumberNavigation);
    setShowCurrencySelector(settings.navigationSettings.ShowHideCurrencySelector);
    setShowHome(settings.navigationSettings.ShowHomeInTheNavigation);
    setShowLanguageSwitcher(settings.navigationSettings.LanguageSwitcher);
    setShowWishlist(settings.navigationSettings.WishlistNavigation);
    setShowWishlistUrl(settings.navigationSettings.WishlistUrl);
    setShowWishlistName(settings.navigationSettings.WishlistUrlName);

    setCurrencies(settings.currencies);
  
    setCurrency(settings.currency);
 
    setDesktopLogo(settings.siteSettings.DesktopLogo);
    setDesktopLogoHeight(settings.siteSettings.DesktopLogoHeight);
    setDesktopLogoWidth(settings.siteSettings.DesktopLogoWidth);
    setMobileLogo(settings.siteSettings.MobileLogo);
    setMobileLogoHeight(settings.siteSettings.MobileLogoHeight);
    setMobileLogoWidth(settings.siteSettings.MobileLogoWidth);
    setShowGoogleTranslate(settings?.NavigationSettings?.ShowGoogleTranslate);

    setLanguages(settings.languages);
    setLanguage(settings.language);

    if (settings.bodyClasses.includes("navigation-dark")){
      setHeaderColorClass('navigation-dark tw-bg-primary text-white');
    } 

    if (settings.bodyClasses.includes("navigation-light") || settings.bodyClasses.includes("navigation-light") === undefined) {
      setHeaderColorClass('navigation-light text-black');
    }  
 
  }, [settings]);

  useEffect(() => {

    let nav = document.getElementsByTagName('body')[0].getAttribute('nav');
 
    if (nav === "1") {
      setEnablenavigation(true);
      return;
    }
    setEnablenavigation(false);
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("sitesettings") === null) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1600);
    } else {
      setIsLoading(false);
    }
  }, []);

  function expandNav() {
    setNavOpen(!navOpen);

    // Toggle the 'no-scroll' class on the body
    if (!navOpen) {
      document.body.classList.add('no-scroll');
      document.body.classList.add('nav-open');
    } else {
      document.body.classList.remove('no-scroll');
      document.body.classList.remove('nav-open');
    }
  }

  const closeMenu = () => {
    setNavOpen(false);
  } 

   
 
  if (!enablenavigation) {
    return;
  }
 
  return (
    <>
 
      {(!isLoading )? 
        <>
          {width > 1024 && 
            <div className={`${headerColorClass} ${home} Navigation tw-p-3 tw-relative tw-flex tw-justify-${centerNav}  xl:tw-items-center`}>
              {enableNavigationLogo && <div className="navigation-logo lg:tw-inline tw-flex tw-justify-between tw-items-center lg:tw-row-span-3 tw-grid-rows-2">
                {desktopLogo !== null ?
                  <a href="/">
                    <img src={desktopLogo} className="img-responsive tw-hidden md:tw-inline desktop-logo" />
                  </a>
                  :
                  null
                }

                {mobileLogo !== null ?
                  <a href="/">
                    <img src={mobileLogo} className="img-responsive md:tw-hidden mobile-logo" />
                  </a>
                  : null
                }


              </div>

              }

              <>
                {width < 1024 ? <div className="tw-space-y-2" onClick={expandNav}>
                  <div className="tw-w-8 tw-h-0.5 tw-bg-gray-600"></div>
                  <div className="tw-w-8 tw-h-0.5 tw-bg-gray-600"></div>
                  <div className="tw-w-8 tw-h-0.5 tw-bg-gray-600"></div>
                </div> : null}
              </>

              <div className="tw-flex lg:tw-justify-stretch lg:tw-items-stretch tw-gap-3">

                <NavigationMenu menuNodes={menuNodes} theme={settings.bodyClasses} open={navOpen} closeMenu={closeMenu} navigationDepth={navigationDepth} />
                {/* {megaMenu && <NavigationMegaMenu menuNodes={menuNodes} theme={settings.bodyClasses} open={navOpen} closeMenu={closeMenu} />} */}

                <div className="service-menu lg:tw-flex lg:tw-justify-end lg:tw-items-center lg:tw-gap-3">
                  <div className=" tw-flex tw-justify-between lg:tw-justify-end lg:tw-items-center lg:tw-gap-3 tw-items-center">
                    {showPhoneNumberNavigation &&
                      <a href={'tel:' + contactTelephone.toString()} className="tw-text-[#8a8a8a] tw-no-underline tw-text-md tw-font-bold">
                         
                        {width > 1024 &&
                          <>
                            {callUsDictionary + " "}
                            <span className="site-phone-text">{contactTelephone}</span>
                          </>
                        }
                      </a>
                    }

                    {showguestLogin &&
                      <a href={guestLoginUrl} className="tw-no-underline" target="_blank" rel="noopener">

                        <span className="guestLoginUrl">{guestLoginUrlName}</span>
                      </a>
                    }

                    {showWishlist &&
                      <a href={showWishlistUrl} className="tw-no-underline tw-mt-4 md:tw-mt-0" target="_blank" rel="noopener">

                        <span className="showWishlistName ">
                          <FontAwesomeIcon icon={faCircleBookmark} style={{ color: '#e0c9a9', fontSize: '30px' }} />
                        </span>
                      </a>
                    }

                    {showLanguageSwitcher && <LanguageSwitcher languages={languages} language={language} />}

                    {showCurrencySelector && <CurrencySelector currecncies={currecncies} />}
                  </div>

                  {showEnableSearch && <PropertyInputSearch />}

                  <GoogleTranslateSwitcher /> 
                </div>

              </div>
            </div>
          }
          {width <= 1024 && 
            <>
             
              <div className="tw-flex tw-justify-between tw-items-center tw-p-4">
                  {mobileLogo !== null ?
                    <a href="/">
                      <img src={mobileLogo} className="img-responsive md:tw-hidden mobile-logo" />
                    </a>
                    : null
                  }
                  <div className='tw-flex tw-justify-between tw-items-center tw-gap-5'>
                    {contactTelephone &&
                      <a href={'tel:' + contactTelephone.toString()} className="tw-no-underline">
                        <FontAwesomeIcon icon={faPhone} style={{ color: '#e0c9a9', fontSize: '27px' }} />
                      </a>
                    }
                    {showWishlist &&
                      <a href={showWishlistUrl} className="tw-no-underline md:tw-mt-0" target="_blank" rel="noopener">

                        <span className="showWishlistName ">
                          <FontAwesomeIcon icon={faCircleBookmark} style={{ color: '#e0c9a9', fontSize: '30px' }} />
                        </span>
                      </a>
                    }
                <div className="tw-space-y-2" onClick={expandNav}>
                  <div className={`tw-transition-transform tw-w-8 tw-h-0.5 tw-bg-gray-600 ${navOpen ? 'rotate-45' : ''}`}></div>
                  <div className={`tw-w-8 tw-h-0.5 tw-bg-gray-600 ${navOpen ? 'opacity-0' : ''}`}></div>
                  <div className={`tw-transition-transform tw-w-8 tw-h-0.5 tw-bg-gray-600 ${navOpen ? 'rotate-135' : ''}`}></div>
                </div>
                  </div>
               </div>
              <div className={`tw-z-100 tw-px-5 tw-fixed tw-h-screen tw-bg-white tw-w-full ${navOpen ? 'nav-animate' : 'tw-hidden'}`}>
                <NavigationMenu
                  menuNodes={menuNodes}
                  theme={settings.bodyClasses}
                  open={navOpen}
                  closeMenu={closeMenu}
                  navigationDepth={navigationDepth}
                />
              <div className='tw-flex tw-justify-between tw-items-center tw-mt-3'>
                {showEnableSearch && <PropertyInputSearch />}

                <GoogleTranslateSwitcher />
              </div>

              </div>

  
              
            </>
          }
        </>
       : 

        <div className={"tw-py-3"}>
          <div className="container-fluid " >
            <div className={`${headerColorClass} Navigation tw-p-3 tw-relative  tw-flex tw-justify-${centerNav}  xl:tw-items-center`}>
              {enableNavigationLogo && <div className="navigation-logo lg:tw-inline tw-flex tw-justify-between tw-items-center lg:tw-row-span-3 tw-grid-rows-2">
                {desktopLogo !== null ?
                       <Skeleton height={30} width={100} />  
                  : null}
                {mobileLogo !== null ?
                  <a href="/">
                    <Skeleton height={30} width={300} />  
                  </a>
                  : null
                }

            
              </div>
              }    
              {
                <div className="tw-hidden lg:tw-grid 2xl:tw-flex lg:tw-justify-stretch lg:tw-items-stretch tw-gap-3">
                  <div className="tw-block tw-p-0 tw-m-0 lg:tw-flex lg:tw-justify-between tw-gap-3">
                    <Skeleton height={20} width={80}  />  
                    <Skeleton height={20} width={80} />  
                    <Skeleton height={20} width={80} /> 
                    <Skeleton height={20} width={80} />  
                    <Skeleton height={20} width={80} />  
                    <Skeleton height={20} width={80} />   
                  </div>
                  
                </div>
              }
            </div>
          </div >
        </div >
      
      
      }
 
      
      <Outlet />
    </>
  );
};

 

export default Navigation;
