import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const SkeletonProperty = (props) => {

  return (
          <>
             {Array(props.count)
              .fill()
              .map((item, index) => (
                <div className='propertyItem' key={index}>

                  <Skeleton height={348} />
                  <h4 className="card-title">
                    <Skeleton height={36} width={`100%`} />
                  </h4>
                  <p className="card-channel">
                    <Skeleton width={`60%`} />
                  </p>
                  <div className="card-metrics">
                    <Skeleton width={`90%`} />
                  </div>
                </div >
              ))}
          </>
  );
};

SkeletonProperty.propTypes = {};

SkeletonProperty.defaultProps = {};

export default SkeletonProperty;
