// GalleryWidget1.jsx
import React, { useEffect, useState } from 'react';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';
import './GalleryWidget1.scss';

const GalleryWidget1 = ({ data }) => {
    const { Title, SubTitle, Content, FeaturedItems } = data;

    const [gridClasses, setGridClasses] = useState('sm:tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4 tw-gap-y-6');

    useEffect(() => {
        if (FeaturedItems){
            const itemsCount = FeaturedItems.length;
            let itemsInRow;
            if (itemsCount > 5) {
                if (itemsCount % 2 === 0) {
                    itemsInRow = itemsCount / 2;
                } else if (itemsCount % 3 === 0) {
                    itemsInRow = itemsCount / 3;
                } else {
                    itemsInRow = Math.floor(itemsCount / 2);
                }
            } else {
                itemsInRow = itemsCount;
            }
            setGridClasses(`tw-grid sm:tw-grid-cols-1 md:tw-grid-cols-${itemsInRow} lg:tw-grid-cols-${itemsInRow} tw-gap-4 tw-gap-y-6`);
        }
    }, [FeaturedItems]);

    return (
        <WidgetWrapper data={data}>
            <h2>{Title}</h2>
            <h3>{SubTitle}</h3>
            <div dangerouslySetInnerHTML={{ __html: Content }} />
            <div className={gridClasses}>
                {FeaturedItems && FeaturedItems.map((item, index) => {
                    if (!item.PropertyUrl || !item.Image || !item.Image.Url || !item.Image.Name || !item.Title) {
                        return null;
                    }

                    return (
                        <a href={item.PropertyUrl} key={index} className="card">
                            <div className="image-container">
                                <img src={item.Image.Url} alt={item.Image.Name} className="image" />
                                <h4 className="image-title">{item.Title}</h4>
                            </div>
                        </a>
                    )
                })}
            </div>
        </WidgetWrapper>
    );
}

export default GalleryWidget1;