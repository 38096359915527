import React from 'react';
import WidgetWrapper from 'components/WidgetsList/WidgetWrapper/WidgetWrapper';
import './MasonryWidget.scss';

const getImageLayout = (imagesCount, index) => {
    let layout = { rowSpan: 1, colSpan: 1 };

    const layouts = {
        1: [[6, 1]],
        2: [[3, 1], [3, 1]],
        3: [[3, 2], [3, 1], [3, 1]],
        4: [[2, 1], [4, 1], [4, 1], [2, 1]],
        5: [[2, 2], [2, 1], [2, 1], [2, 1], [2, 1]],
        6: [[3, 1], [2, 1], [1, 1], [1, 1], [3, 1], [2, 1]],
        7: [[2, 1], [1, 1], [1, 1], [2, 1], [2, 1], [3, 1], [1, 1]],
        8: [[2, 1], [1, 1], [1, 1], [2, 1], [2, 1], [1, 1], [2, 1], [1, 1]],
        9: [[2, 2], [2, 1], [2, 1], [4, 1], [2, 1], [1, 1], [1, 1], [1, 1], [1, 1]],
    };

    let layoutPattern = imagesCount <= 9 ? imagesCount : imagesCount % 9;

    if (layouts[layoutPattern]) {
        let layoutIndex = index % layouts[layoutPattern].length;
        [layout.colSpan, layout.rowSpan] = layouts[layoutPattern][layoutIndex];
    }

    return layout;
};

const truncate = (str, num) => {
    if (str.length <= num) {
        return str;
    }
    return str.slice(0, num) + "...";
};

const MasonryWidget = ({ data }) => {
    const { Title, SubTitle, Content, FeaturedItems } = data;

    return (
        <WidgetWrapper data={data}>
            <div className="tw-p-5 tw-bg-white tw-shadow-lg tw-rounded-md">
                {Title && <h2>{Title}</h2>}
                {SubTitle && <h3>{SubTitle}</h3>}
                <div className="tw-mb-5" dangerouslySetInnerHTML={{ __html: Content }} />
                <div className="md:tw-grid tw-grid-cols-6 tw-gap-4">
                    {FeaturedItems.map((item, index) => {
                        const layout = getImageLayout(FeaturedItems.length, index);
                        return (
                            <a
                                href={item.PropertyUrl || '#'}
                                key={index}
                                className={`
                                    ${layout.rowSpan === 1 ? 'grid-item-single' : 'grid-item-double'}
                                    tw-relative tw-border tw-border-gray-200 tw-rounded tw-overflow-hidden masonry-image tw-shadow-md 
                                    tw-row-span-${layout.rowSpan} tw-col-span-${layout.colSpan}
                                `}
                            >
                                <div className="tw-w-full tw-h-full tw-flex tw-flex-col tw-relative">
                                    <div className="tw-flex tw-justify-center tw-items-center tw-h-full ">
                                        <img src={item.Image.Url} alt={item.Image.Name} className="tw-object-cover tw-w-full tw-h-full masonry-image" />
                                    </div>
                                    {item.Title &&
                                        <div className="tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bg-black tw-bg-opacity-50 tw-p-3">
                                            <h3 className="tw-text-lg tw-font-semibold tw-mb-2 tw-text-white">{item.Title}</h3>
                                        </div>
                                    }
                                    {item.Notes &&
                                        <div className="tw-min-h-[70px] masonry-wrapper-desc tw-hidden lg:tw-flex tw-items-center tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-bg-black tw-bg-opacity-50 tw-p-3">
                                            <div className="tw-text-white" dangerouslySetInnerHTML={{ __html: truncate(item.Notes, 110) }} />
                                        </div>
                                    }
                                </div>
                            </a>
                        );
                    })}
                </div>
            </div>
        </WidgetWrapper>
    );
};

export default MasonryWidget;